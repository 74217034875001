import {createRouter, createWebHistory} from 'vue-router'
import Main from '../views/Main'
import Users from '../views/Users'
import allLessons from '../views/allLessons.vue'
import lessonvue from '../views/lesson'
import login from '../views/Login.vue'
import cabinet from '../views/Cabinet'
import createLesson from '../views/CreateLesson'
import createQuestion from '../views/CreateQustion'
import NewUsers from  '../views/NewUsers'
import dashboard from  '../views/dashboard'
import newLectures from  '../views/newLectures'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Main
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboard
  },
  {
    path: '/users',
    name: 'users',
    component: Users
  },
  {
    path: '/newusers',
    name: 'newusers',
    component: NewUsers
  },
  {
    path: '/createlesson',
    name: 'createlesson',
    component: createLesson
  },
  {
    path: '/createquestion',
    name: 'createquestion',
    component: createQuestion
  },
  {
    path: '/allLessons',
    name: 'allLessons',
    component: allLessons
  },
  {
    path: '/lessonvue/:id',
    name: 'lessonVue',
    component: lessonvue
  },
  {
    path: '/login',
    name: 'Login',
    component: login
  },
  {
    path: '/cabinet',
    name: 'Cabinet',
    component: cabinet
  },
  {
    path: '/newLectures',
    name: 'NewLectures',
    component: newLectures
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
