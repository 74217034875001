<template>
  <div class="container login-form d-flex d-flex align-items-center text-center col-lg-6 col-12">
    <div class="form-content">
      <div class="form w-100 m-auto">
        <h1 class="litle">Авторизація</h1>
        <div class="form-floating ">
          <form @submit.prevent class="form w-50">

            <div class="form-floating">
              <input
                  v-bind:value="mail"
                  @input="mail = $event.target.value"
                  type="pass"
                  class="form-control"
                  id="mail"
                  placeholder="Іван Іванченко">
              <label for="mail" class="lable">Email *</label>
            </div>

            <div class="form-floating">
              <input
                  v-bind:value="password"
                  @input="password = $event.target.value"
                  type="password"
                  class="form-control"
                  id="pass"
                  placeholder="Іван Іванченко">
              <label for="pass" class="lable">Пароль *</label>
              <div id="passwordHelpBlock" class="form-text">
                Пароль надсилається у листі на електронну адресу після підтвердження реєстрації нашою командою. Якщо виникли проблеми з входом на платформу - зателефонуйте на гарячу лінію: <br>
<div class="phones">
  <a href="tel:063-876-94-49">
    (📞063-876-94-49)
  </a>
  <a href="tel:067-876-94-49">
    (📞067-876-94-49)
  </a>
  <a href="tel:050-876-94-49">
    (📞050-876-94-49)
  </a>
</div>
              </div>
            </div>
            <button class="btn btn-outline-dark form__btn col-12" @click="signin">Увійти</button>

            <div class="mt-3 col-12">
              <a class="ress_pass" href="https://edu.dniprorada.gov.ua/#form">Реєстрація</a>
            </div>

            <div class="mt-3 col-12">
              <a class="ress_pass" data-bs-toggle="modal" data-bs-target="#exampleModal">Відновити пароль</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade resetForm" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content resetForm">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Відновлення пароля</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <input type="text" class="login__input col-12 resetPas" placeholder="Ваш email" v-model="resmail">
        </div>
        <div class="modal-footer">
          <button type="btn btn-outline-dark form__btn" class="new__btn resetButton" data-bs-dismiss="modal" aria-label="Close" @click="resPass">Відновити
            пароль
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex'
import instance from '@/instances/authentigicate_instance';
import router from "../router";

export default {
  name: "Login",
  data() {
    return {
      mail: '',
      password: '',
      resmail: '',
    };
  },
  methods: {
    async resPass() {
      try {
        var data = {
        'mail': this.resmail,
      }
      instance.post('/resetuserbyemail/', data).then((json) => {
        this.$swal.fire({
          icon: 'success',
          title: 'Успішно! Перевірте пошту, вам прийшов новий пароль',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        })
      }).catch((err) => {
        this.$swal.fire({
          icon: 'error',
          title: 'Помилка!',
          text: 'Перевірте правильнність пошти',
          showConfirmButton: false,
          timer: 1500
        })
      });
      } catch (error) {
        this.$swal.fire(
            'Ой! Щось пішло не так',
            'Обовʼязково перевіряйте чи всі обовʼязкові поля форми ви заповнили.',
            'warning'
        )
      }
    },
    async signin() {
      if (this.mail !== '' && this.password !== '') {
        var data = {
          'mail': this.mail,
          'password': this.password
        }
        instance.post('/users/authenticate', data).then((json) => {
          localStorage.setItem('token', json.data.token)
          this.$swal.fire({
            icon: 'success',
            title: 'Успішно!',
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          })
          if (json.data.token) {
            this.setUser(json.data.user)
            router.push('/cabinet')
          } else {
            this.$swal.fire({
              icon: 'error',
              title: 'Помилка!',
              text: 'Перевірте правильнність пошти та пароля.',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }).catch((err) => {
          this.$swal.fire({
            icon: 'error',
            title: 'Помилка!',
            text: 'Перевірте правильнність пошти та пароля.',
            showConfirmButton: false,
            timer: 1500
          })
        });
      } else {
        this.$swal.fire(
            'Ой! Щось пішло не так',
            'Обовʼязково перевіряйте чи всі обовʼязкові поля форми ви заповнили.',
            'warning'
        )
      }
    },
    ...mapMutations({setUser: 'user/setUser'})
  },
  computed: {
    ...mapGetters({user: 'user/user'})
  },
  created() {
    if (this.user.name) {
      router.push('/cabinet')
    }
  }
}
</script>

<style scoped>
.login-form {
  margin-top: 150px;
}

.form-content {
  width: 100%;
  border-radius: 50px;
  margin-bottom: 20px;
  margin-top: calc(100% / 10);
  box-shadow: 0px 4px 20px 0px rgb(0 0 0 / 10%);
}

.lable {
  font-size: calc(10px + 3 * (100vw / 1280)) !important;
  margin-left: 10px;
}

.form {
  padding-bottom: 30px;
  margin: auto;
  background: #fff;
  border-radius: 50px;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
  -webkit-text-fill-color: black !important; /* цвет текста */
  color: black !important; /* цвет текста */
}

.form-control {
  margin-top: 60px;
  box-shadow: none !important;
  border-radius: 50px;
  border: 2px solid #8C9FD7;
}

.resetForm {
  border-radius: 50px;
  padding: 30px;
}

.resetButton {
  background: #8C9FD7;
  color: white;
  border-radius: 50px;
  border: 2px solid #8C9FD7;
  transition: 0.3s ease-in;
  padding: 1px 50px;
  text-transform: uppercase;
  height: 50px;
  width: 100%;
}

.resetPas {
  box-shadow: none !important;
  border-radius: 50px;
  border: 2px solid #8C9FD7;
  height: 50px;
}

.ress_pass:hover {
  transition: 1s;
  cursor: pointer;
}

.ress_pass{
  padding-bottom: 20px;
}

.litle {
  padding-top: calc(100% / 20);
}

.form-text {
  margin-top: 20px;
  margin-left: 15px;
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: 12px;
}

.phones{
  margin-top: 10px;
}

.phones a{
  text-align: justify !important;
}

h1 {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: 56px;
}

.form__btn {
  margin-top: 50px;
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: calc(20px + 10 * (100vw / 1280));
  background: #8C9FD7;
  color: white;
  border-radius: 50px;
  border: 2px solid #8C9FD7;
  transition: 0.3s ease-in;
  padding: 1px 50px;
  text-transform: uppercase;
}
</style>
