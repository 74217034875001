<template>
  <Home v-motion/>
  <Pairs v-motion :initial="{opacity: 0}" :visible="{opacity: 1}"/>
  <About v-motion :initial="{opacity: 0}" :visible="{opacity: 1}"/>
  <Subjects v-motion :initial="{opacity: 0}" :visible="{opacity: 1}"/>
  <Registration v-motion :initial="{opacity: 0}" :visible="{opacity: 1}"/>
</template>

<script>
// @ is an alias to /src
import Home from '@/components/Home.vue'
import About from '@/components/About.vue'
import Subjects from "../components/Subjects";
import Registration from "../components/Registration";
import Pairs from "../components/pairs";

export default {
  name: 'Main',
  components: {
    Home,
    About,
    Subjects,
    Registration,
    Pairs
  },
  created() {
    window.scroll(0, 0);
  }
}
</script>

