<template>
  <div v-for="user in users" v-bind:key="user.id">
    <div class="row pt-5">
      <div class="col-md-1">
        <h6>{{ user.id }}</h6>
      </div>
      <div class="col-md-2">
        <h6>{{ user.name }}</h6>
      </div>
      <div class="col-md-2">
        <h6>{{ user.mail }}</h6>
      </div>
      <div class="col-md-2">
        <h6>{{ user.phone }}</h6>
      </div>
      <div class="col-md-1">
        <h6>{{ user.sity }}</h6>
      </div>
      <div class="col-md-1" v-if="user.sity === 'Дніпро'">
        <h6>{{ user.school }}</h6>
      </div>
      <div class="col-md-1" v-else>
        <h6>-</h6>
      </div>
      <div class="col-md-2">
        <h6 v-if="user.course === 2">Українська мова</h6>
        <h6 v-if="user.course === 1">Математика</h6>
        <h6 v-if="user.course === 3">Історія України</h6>
        <h6 v-if="user.course === 4">Загальний курс</h6>
      </div>
      <div class="col-md-1">
        <button v-if="user.status !== 1" type="button" class="btn btn-outline-success"
                @click="initUser(user.id, user.mail, user.name)">Активувати
        </button>
        <button v-else-if="user.status === 1" type="button" class="btn btn-success" disabled>Активовано</button>
        <button v-if="user.status === 1" type="button" class="btn btn-outline-danger mt-2"
                @click="resetPass(user.id, user.mail, user.name)">Новий пароль
        </button>
        <button v-if="user.status === 2" type="button" class="btn btn-danger mt-2"
                @click="block(user.mail, user.name)" disabled>Відмовлено
        </button>
        <button v-else-if="user.status === 0" type="button" class="btn btn-outline-danger mt-2"
                @click="block(user.mail, user.name, user.id)">Відмова
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "NewUsers",
  data() {
    return {
      users: [],
      count: [],
    };
  },

  async created() {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/getnewusers`)
      this.users = response.data;
      console.log(this.items);
    } catch (err) {
      console.log(err);
    }
  },

  methods: {
    async block(mail, name, id) {
      this.users.find(el => el.id === id).status = 2
      try {
        this.$swal.fire(
            {
              icon: 'error',
              title: 'Користувачу відмовлено',
            }
        )
        await axios.post(
            `${process.env.VUE_APP_API_URL}/block`,
            {
              name: name,
              mail: mail,
              id: id,
            }, {withCredentials: true}
        )
      }
      catch (err){
        this.$swal.fire(
            {
              icon: 'error',
              title: err,
            }
        )
      }
    },

    async initUser(id, mail, name) {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/inituser/${id}`, {
          mail: mail,
          name: name
        }, {withCredentials: true});

        this.$swal.fire(
            {
              icon: 'success',
              title: 'Активовано',
            }
        )
        this.users.find(el => el.id === id).status = 1
      } catch (err) {
        this.$swal.fire(
            {
              icon: 'error',
              title: 'Помилка!',
              text: err,
            }
        )
      }
    },
    async resetPass(id, mail, name) {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/resetuser/${id}`, {
          mail: mail,
          name: name
        }, {withCredentials: true});

        this.$swal.fire(
            {
              icon: 'success',
              title: 'Пароль користувача оновлено',
            }
        )
      } catch (err) {
        this.$swal.fire(
            {
              icon: 'error',
              title: 'Помилка!',
              text: err,
            }
        )
      }
    },
  },
}
</script>

<style scoped>
h6 {
  font-family: e-Ukraine, -apple-system, sans-serif;
  font-size: 18px;
  font-weight: 400;
}
</style>
