<template>
  <div class="container login-form d-flex d-flex align-items-center text-center col-lg-6 col-12">
    <div class="form-content">
      <div class="form m-auto">
        <h1 class="pt-5 form_title">Створити питання</h1>
        <div class="form-floating ">
          <form @submit.prevent class="form w-50">

            <div class="form-floating">
              <input
                  v-bind:value="name"
                  @input="name = $event.target.value"
                  type="text"
                  class="form-control form-control-input"
                  id="name">
              <label for="name" class="lable">Питання</label>
            </div>

            <div class="form-floating">
              <textarea class="form-control form-control-textarea" style="height: 100px"
                        v-bind:value="text"
                        @input="text = $event.target.value"
                        id="text"
                        cols="100"
              ></textarea>
              <label for="text" class="lable">Текст</label>
            </div>

            <div class="form-floating">
              <input
                  v-bind:value="task"
                  @input="task = $event.target.value"
                  type="text"
                  class="form-control form-control-input"
                  id="task">
              <label for="task" class="lable">Завдання</label>
            </div>

            <upload :files="img" />

            <div class="m-auto">
              <select
                  v-bind:value="lecture_id"
                  v-model="lecture_id"
                  class="form-control form-control-input"
                  id="category">
                <option selected disabled value="-1">Оберіть лекцію</option>
                <option :value="lecture.id" v-for="lecture of this.lectures">{{ lecture.title }}</option>
              </select>
            </div>

            <button class="btn btn-outline-dark form__btn mb-5 col-12" @click="create()">Створити</button>
            <button class="btn btn-outline-success  mb-5 col-12" @click="createAnswers()">Створити відповідь</button>
          </form>

          <div class="hr w-100"></div>

          <div class="row justify-content-between mt-5 titles">
            <div class="col-6"><h2>Питання</h2></div>
            <div class="col-6"><h2>Правильна відповідь</h2></div>
          </div>
          <div class="" v-for="answer of answers">
            <div class="row justify-content-between border-top">
              <div class="col-6 answ"><h2>{{ answer.name }}</h2></div>
              <div class="col-6 answ"><h2>{{ answer.is_correct === true ? 'Так' : 'Ні' }}</h2></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../router";
import {mapGetters} from 'vuex'
import axios from "axios";
import upload from "../components/upload";

export default {
  name: "CreateQestion",
  data() {
    return {
      name: '',
      text: '',
      task: '',
      img: [],
      lecture_id: -1,
      lectures: [],
      answer_name: '',
      question_id: 0,
      is_correct: '',
      answers: [],
      formValues: []

    };
  },
  components:{
    upload
  },
  methods: {
    async create() {
      try {
        await axios.post(
            `${process.env.VUE_APP_API_URL}/createqestion`,
            {
              name: this.name,
              text: this.text,
              task: this.task,
              position: this.position,
              lecture_id: this.lecture_id,
              answers: this.answers,
              image: this.img.length > 0 ? process.env.VUE_APP_API_URL + this.img[0].URL : null,
            }, {withCredentials: true}
        )
        this.name = ''
        this.position++
        this.answers = []
        this.text=''
        this.task=''
        this.$swal.fire(
            {
              icon: 'success',
              title: 'Успешно!',
            }
        )
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: err,
        })

      }
    },
    async createAnswers() {
      const {value: formValues} = await this.$swal.fire({
        title: 'Створити відповідь',
        html:
            '<input id="answer_name" placeholder="Відповідь" type="text" class="swal2-input">' +
            '<input type="hidden" class="swal2-input" v-bind:value="question_id" v-model="question_id">' +
            '<label for="is_correct" class="swal2-lable">Правильна відповідь</label>' +
            '<input type="checkbox" id="is_correct" class="swal2-checkbox">',
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('answer_name').value,
            document.getElementById('is_correct').checked
          ]
        }
      })
      let [name, is_correct] = formValues;
      this.answers.push({'name': name, 'is_correct': is_correct})
    }
  },
  computed: {
    ...mapGetters({user: 'user/user'})
  },

  async created() {
    if (this.user.role !== 1) {
      router.push('/')
    }

    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/getalllectures`);
      this.lectures = response.data;
    } catch (err) {
      console.log(err);
    }
  },
}
</script>

<style scoped>

.answ {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: calc(20px + 3 * (100vw / 1280)) !important;
  padding: 20px 0;
}

.titles {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: calc(30px + 3 * (100vw / 1280)) !important;
  padding-bottom: 20px;
}

.form_title {
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  font-size: calc(40px + 3 * (100vw / 1280)) !important;
}

.form-content {
  border-radius: 50px;
  margin-top: calc(100% / 5);
  width: 100%;
  box-shadow: 0px 4px 20px 0px rgb(0 0 0 / 10%);
}

.lable {
  font-size: calc(10px + 3 * (100vw / 1280)) !important;
  margin-left: 10px;
}

.form {
  margin: auto;
  background: #fff;
  border-radius: 50px;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
  -webkit-text-fill-color: black !important; /* цвет текста */
  color: black !important; /* цвет текста */
}

.form-control-input {
  height: 60px;
  margin-top: 60px;
  box-shadow: none !important;
  border-radius: 50px;
  border: 2px solid #8C9FD7;
}

.form-control-textarea {
  height: 60px;
  margin-top: 60px;
  box-shadow: none !important;
  border-radius: 16px;
  border: 2px solid #8C9FD7;
}

h1 {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: 56px;
}

.form__btn {
  margin-top: 30px;
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: calc(20px + 10 * (100vw / 1280));
  background: #8C9FD7;
  color: white;
  border-radius: 50px;
  border: 2px solid #8C9FD7;
  transition: 0.3s ease-in;
  padding: 1px 50px;
  text-transform: uppercase;
}
</style>
