<template>
  <div class="users container-fluid text-center mt-5">
    <h1 class="m-5">Усі користувачі</h1>
    <div class="row pt-5 mb-5">
      <div class="col-lg-1">
        <h6>ID</h6>
      </div>
      <div class="col-lg-2">
        <h6>Імʼя та Прізвище</h6>
      </div>
      <div class="col-lg-2">
        <h6>Електронна пошта</h6>
      </div>
      <div class="col-lg-2">
        <h6>Контактний номер</h6>
      </div>
      <div class="col-md-1">
        <h6>Місто</h6>
      </div>
      <div class="col-md-1">
        <h6>Школа</h6>
      </div>
      <div class="col-lg-2">
        <h6>Обраний курс</h6>
      </div>
      <div class="col-lg-1">
        <h6>Стаус</h6>
      </div>
    </div>
    <newUser/>
  </div>
</template>

<script>
// @ is an alias to /src
import newUser from "../components/NewUser";
import {mapGetters} from 'vuex'
import router from "../router";

export default {
  name: 'Users',
  components: {
    newUser,
  },
  created() {
    if (this.user.role !== 1){
      router.push('/')
    }
  },
  computed: {
    ...mapGetters({user:'user/user'})
  }
}

</script>


<style scoped>
h6 {
  font-family: e-Ukraine, -apple-system, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.users {
  padding: 30px;
  border-radius: 16px;
}

</style>

