<template>
  <a name="video"></a>
  <section class="container lecture text-center">
    <div class="row d-flex justify-content-center">

<!--      <div class="col-12">-->
<!--        <h1 v-if="results.category === 1" class="lecture__title">Курс: Математика</h1>-->
<!--        <h1 v-if="results.category === 2" class="lecture__title">Курс: Українська мова</h1>-->
<!--        <h1 v-if="results.category === 3" class="lecture__title">Курс: Історія України</h1>-->
<!--        <h2 class="lecture__text">{{ results.title }}</h2>-->
<!--      </div>-->

      <div v-if="results.video_id" class="col-12 col-lg-8">
        <div class="ratio ratio-16x9 m-auto">
          <YoutubeVue3 ref="player" :videoid="results.video_id" class="rounded mt-5" autoplay="1" controls="1"/>
        </div>
      </div>
    </div>
  </section>

  <div class="col-12 text-center help" v-if="results.documents">
    <h2><a :href="results.documents" class="primary" target="_blank">Матеріали для самопідготовки</a></h2>
  </div>

  <section class="container lecture__test" v-if="questions.length > 0">
    <div class="row">
      <div class=" col-12">
        <h1 class="test__title text-center mt-5" v-if="results.video_id">Тест до теми: <h3>{{ results.title }}</h3></h1>

        <div class="hr w-100 mt-5" ></div>

        <div class="answers">

          <div class="row mt-5">
            <div class="col-12 text-center">
              <div class="answer__title"
                   v-if="results.category === 1 && this.curantQuestion.name.indexOf('$$') !== -1 &&  this.curantQuestion.name.split(':')[0] !== 'http'">
                <vue-mathjax :formula="this.curantQuestion.name" :safe="true"></vue-mathjax>

              </div>

              <img :src="this.curantQuestion.name" v-else-if="this.curantQuestion.name.split(':')[0] === 'http' || this.curantQuestion.name.split(':')[0] === 'https'">


              <h2 class="answer__title" v-else>
                {{ this.curantQuestion.name }}
              </h2>

              <div class="answer__title col-12 container">


                <img :src="this.curantQuestion.image" class="lesson__img">

              </div>

              <div v-if="this.curantQuestion.text" class="text overflow-scroll" style="overflow-x: hidden !important">
                <pre>{{ this.curantQuestion.text }}</pre>
              </div>

              <h2 v-if="this.curantQuestion.task" class="answer__title">
                {{ this.curantQuestion.task }}
              </h2>

            </div>

            <div class="mt-5 mb-5 row">
              <div v-for="ans in this.curantQuestion.answers" :key="ans.id" class="col-lg-4 col-12 que">
                <div class="answers">
                  <div class="form-check">
                    <input class="form-check-input test__checkBox d-flex" type="radio" :id="ans.name"
                           :name="this.curantQuestion.name" :value="ans.name" v-model="answer"
                           :disabled="toNext === false">

                    <label class="form-check-label test__checkBox__label d-flex" :for="ans.name"
                           v-if="ans.name.includes('$$')">
                      <vue-mathjax :formula="ans.name"></vue-mathjax>
                    </label>

                    <label class="form-check-label test__checkBox__label d-flex" :for="ans.name"
                           v-else>
                      {{ ans.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="hr w-100 mt-5"></div>

    <div class="row mt-5 text-center">
      <div class="col-md-6 col-12" v-if="results.video_id">
        <button onclick="location.href = '#video'" class="btn btn-outline-dark mt-3 form__btn">Повернутись до лекції
        </button>
      </div>

      <div class="col-md-6 col-12" v-if="results.video_id">
        <button type="button" class="btn btn-dark mt-3 form__btn" @click="checkAnswer(this.curantQuestion.answers)"
                :disabled="toNext === false">
          Перевірити
        </button>
      </div>
      <div class="col-md-12 col-12" v-if="!results.video_id">
        <button type="button" class="btn btn-dark mt-3 form__btn" @click="checkAnswer(this.curantQuestion.answers)"
                :disabled="toNext === false">
          Перевірити
        </button>
      </div>
    </div>

  </section>
</template>

<script>
import {YoutubeVue3} from 'youtube-vue3'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import axios from "axios";

export default {
  name: 'lessonvue',
  data() {
    return {
      step: 0,
      answer: 0,
      score: 0,
      toNext: true,
      questions: [],
      curantQuestion: [],
      answers: [],
    }
  },
  components: {
    YoutubeVue3,
  },
  computed: {
    ...mapGetters({
      results: 'lecture/results'
    }),
  },
  methods: {
    ...mapActions({
      lectureQuery: 'lecture/lectureQuery',
    }),
    ...mapMutations({
      setResponse: 'lecture/setResponse',
    }),

    checkAnswer(a) {
      console.log(this.curantQuestion.name);
      const corr = a.find(el => el.name === this.answer).correct
      if (this.results.category === 1) {
        if (this.step !== this.questions.length - 1) {
          if (corr === 1) {
            this.score += 1;
            this.answer = '';
            this.step += 1;
            this.curantQuestion = this.questions[this.step]
            this.$swal.fire(
                {
                  icon: 'success',
                  title: 'Правильно!',
                }
            )
          } else {
            if (a.find(el => el.correct === 1).name.slice(1)[0] === '$') {
              this.$swal({
                icon: 'error',
                title: 'Не правильно!',
                text: 'Правильна відповідь: ' + a.find(el => el.correct === 1).name.slice(2)[0],
                showDenyButton: true,
                denyButtonText: `Завершити`,
                confirmButtonText: 'Далі',
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  this.step += 1;
                  this.curantQuestion = this.questions[this.step]
                  this.answer = '';
                } else if (result.isDenied) {
                  this.$swal.fire('Вітаємо!', 'Ваш бал: ' + this.score, 'info')
                  this.toNext = false;
                }
              })
            } else {
              this.$swal({
                icon: 'error',
                title: 'Не правильно!',
                text: 'Правильна відповідь: ' + a.find(el => el.correct === 1).name,
                showDenyButton: true,
                denyButtonText: `Завершити`,
                confirmButtonText: 'Далі',
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  this.step += 1;
                  this.curantQuestion = this.questions[this.step]
                  this.answer = '';
                } else if (result.isDenied) {
                  this.$swal.fire('Вітаємо!', 'Ваш бал: ' + this.score, 'info')
                  this.toNext = false;
                }
              })
            }

          }
        } else {
          if (corr === 1) {
            this.score += 1;
            this.$swal({
              icon: 'success',
              title: 'Правильно',
              confirmButtonText: 'Завершити',
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.$swal.fire('Вітаємо!', 'Правильних відповідей: ' + this.score + ' із ' + this.questions.length, 'info')
                this.answer = '';
              }
            })
            this.toNext = false;
          } else {
            if (a.find(el => el.correct === 1).name.slice(1)[0] === '$') {
              this.$swal({
                icon: 'error',
                title: 'Не правильно!',
                text: 'Правильна відповідь: ' + a.find(el => el.correct === 1).name.slice(2)[0],
                confirmButtonText: 'Завершити',
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  this.$swal.fire('Вітаємо!', 'Правильних відповідей: ' + this.score + ' із ' + this.questions.length, 'info')
                }
              })
            } else {
              this.$swal({
                icon: 'error',
                title: 'Не правильно!',
                text: 'Правильна відповідь: ' + a.find(el => el.correct === 1).name,
                confirmButtonText: 'Завершити',
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  this.$swal.fire('Вітаємо!', 'Правильних відповідей: ' + this.score + ' із ' + this.questions.length, 'info')
                }
              })
            }
            this.toNext = false;
          }
        }
      } else {
        if (this.step !== this.questions.length - 1) {
          if (corr === 1) {
            this.score += 1;
            this.answer = '';
            this.step += 1;
            this.curantQuestion = this.questions[this.step]
            this.$swal.fire(
                {
                  icon: 'success',
                  title: 'Правильно!',
                }
            )
          } else {
            this.$swal({
              icon: 'error',
              title: 'Не правильно!',
              text: 'Правильна відповідь: ' + a.find(el => el.correct === 1).name,
              showDenyButton: true,
              denyButtonText: `Завершити`,
              confirmButtonText: 'Далі',
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.step += 1;
                this.curantQuestion = this.questions[this.step]
                this.answer = '';
              } else if (result.isDenied) {
                this.$swal.fire('Вітаємо!', 'Ваш бал: ' + this.score, 'info')
                this.toNext = false;
              }
            })
          }
        } else {
          if (corr === 1) {
            this.score += 1;
            this.$swal({
              icon: 'success',
              title: 'Правильно',
              confirmButtonText: 'Завершити',
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.$swal.fire('Вітаємо!', 'Правильних відповідей: ' + this.score + ' із ' + this.questions.length, 'info')
                this.answer = '';
              }
            })
            this.toNext = false;
          } else {
            this.$swal({
              icon: 'error',
              title: 'Не правильно!',
              text: 'Правильна відповідь: ' + a.find(el => el.correct === 1).name,
              confirmButtonText: 'Завершити',
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.$swal.fire('Вітаємо!', 'Правильних відповідей: ' + this.score + ' із ' + this.questions.length, 'info')
              }
            })
            this.toNext = false;
          }
        }
      }
    },

    applyConfig() {
      this.play = Object.assign(this.play, this.temp)
    },
    playCurrentVideo() {
      this.$refs.youtube.player.playVideo();
    },
    stopCurrentVideo() {
      this.$refs.youtube.player.stopVideo();
    },
    pauseCurrentVideo() {
      this.$refs.youtube.player.pauseVideo();
    },
    onEnded() {
      console.log("## OnEnded")
    },
    onPaused() {
      console.log("## OnPaused")
    },
    onPlayed() {
      console.log("## OnPlayed")
    }
  },
  async created() {
    this.lectureQuery(this.$route.params.id)

    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/getalllquestion/${this.$route.params.id}`);
      this.questions = response.data;
      this.curantQuestion = response.data[0]
    } catch (err) {
      console.log(err);
    }
  }
}
</script>

<style scoped>

.text {
  padding: 30px;
  margin: auto;
  margin-top: 50px;
  background: #fff;
  max-height: 500px;
  max-width: 90%;
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: calc(14px + 5 * (100vw / 1280));
  border-radius: 5px;
  border: none !important;
  text-align: left;
  letter-spacing: 1px;
  line-height: 35px;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.3);
}

.text::-webkit-scrollbar,
.text::-webkit-scrollbar-thumb {
  width: 26px !important;
  border-radius: 13px !important;
  background-clip: padding-box !important;
  border: 10px solid transparent !important;
}

.text::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

.text pre {
}

.help {
  margin-top: 120px;
}

.primary {
  padding: calc(100% / 70) calc(100% / 65);
  color: white;
  border-radius: 50px;
  background: #F48E69;
  font-weight: bold;
}

.hr {
  background: #212121;
  height: 1px;
  border-radius: 16px;
}

.lecture {
  padding-top: 100px;
  margin-top: calc(50% / 15);
}

.lecture__title {
  display: inline-block;
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  font-size: calc(64px + 3 * (100vw / 1280));
}

.lecture__text {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: calc(25px + 3 * (100vw / 1280));
  margin-top: 50px;
  font-weight: 400;
}

.lecture__test {
  margin-top: 30px;
}

.test__title {
  font-size: 64px;
  font-weight: 600;
}

.form__btn {
  width: 90%;
  height: 70px;
}

.answers {
  text-align: center;
  margin-top: 30px;
}

.answer__title {
  margin-top: 30px;
  font-family: e-Ukraine, -apple-system, sans-serif;
  font-size: 42px;
  font-weight: 400;
}

.test__checkBox {
  width: 30px;
  height: 30px;
  background: none;
  margin-right: 10px;
  border: #212121 solid 1px;
  box-shadow: none !important;
}

.test__checkBox:checked:before {
  width: 15px;
  height: 15px;
  background: #212121;
  border-radius: 50%;
  content: '';
  margin: auto;
}

.test__checkBox__label {
  font-family: e-Ukraine, -apple-system, sans-serif;
  font-size: 24px;
  font-weight: 300;
}

iframe {
  justify-items: center !important;
  display: flex;
  margin: auto !important;
}

</style>
