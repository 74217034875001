import {createStore} from 'vuex';
import {getLecture} from './getLecture'
import {userStore} from './userStore'
import createPersistedState from "vuex-persistedstate"

export default createStore({
    plugins: [createPersistedState()],
    modules: {
        lecture: getLecture,
        user: userStore,
    }
})
