<template>
  <a name="top"></a>
  <div class="container">
    <div class="row justify-content-center mt-5">

      <div class="lessons" v-if="lectures.length > 0">
        <div v-for="lecture in lectures">

          <div v-if="lecture.id > 10" class="col-12 lecture" :initial="{opacity: 0}" :visible="{opacity: 1}">
            <div class="row d-flex justify-content-between align-items-center">
              <div class="col-md-6 col-12">
                <h2 class="date">{{
                    new Date(lecture.publickdate).toLocaleString('uk', {
                      month: 'long',
                      day: 'numeric',
                      weekday: 'long',
                    }).toUpperCase()
                  }}</h2>
                <h3 class="subject">{{ lecture.title }}</h3>
              </div>
              <div class="col-md-4 col-12">
                <router-link :to="{ name: 'lessonVue', params: { id: lecture.id } }">
                  <button class="btn btn-outline-dark mt-3 form__btn align-items-center">Перейти</button>
                </router-link>
              </div>
            </div>
            <div class="hr w-100 mt-5"></div>
          </div>

          <div v-else>
            <div class="row d-flex justify-content-between align-items-center">
              <div class="col-md-6 col-12">
                <h2 class="date">{{
                    new Date(lecture.publickdate).toLocaleString('uk', {
                      month: 'long',
                      day: 'numeric',
                      weekday: 'long',
                    }).toUpperCase()
                  }}</h2>
                <h3 class="subject">{{ lecture.title }}</h3>
              </div>
              <div class="col-md-4 col-12">
                <router-link :to="{ name: 'lessonVue', params: { id: lecture.id } }">
                  <button class="btn btn-outline-dark mt-3 form__btn align-items-center">Перейти</button>
                </router-link>
              </div>
            </div>
            <div class="hr w-100 mt-5"></div>
          </div>

        </div>
      </div>

      <div class="missing col-12" v-else-if="lectures && lectures.length === 0">
        <div class="col-12">
          <h2 class="text_err" v-if="this.$route.query.subject === '10' || this.$route.query.subject === '11' ||this.$route.query.subject === '12'">Перша лекція <br/> відбудеться 22 квітня</h2>
          <h2 class="text_err" v-else>Перша лекція <br/> відбудеться 1 квітня</h2>
        </div>
      </div>

      <div class="missing col-12" v-else>
        <div class="col-12">
          <h2 class="text_err">Завантаження ...</h2>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapGetters} from 'vuex'

import axios from "axios";
import router from "../router";

export default {
  name: 'Lessons',
  components: {},
  data() {
    return {
      lectures: {},
    }
  },
  methods: {
    // Получить все курсы по математикке
    async getAllMath() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/getalllessons/?subject=${this.$route.query.subject}`);
        this.lectures = response.data;
      } catch (err) {
        console.log(err);
      }
    },
  },
  created() {
    // if (!this.user.course) {
    //   router.push('/')
    // }
    window.scroll(0, 0);
  },
  // computed: {
  //   ...mapGetters({user: 'user/user'})
  // },
  mounted() {
    this.getAllMath()
  }
}
</script>

<style scoped>

.missing {
  text-align: center;
  height: 50vh;
  margin-top: calc(100% / 12);
}

.title_err {
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  font-size: calc(100px + 16 * (100vw / 1280));
}

.text_err {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: calc(50px + 16 * (100vw / 1280));
}

.lessons-title {
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  font-size: calc(60px + 4 * (100vw / 1280));
  margin-top: calc(100% / 12);
}

.lessons {
  margin-top: 50px;
}

.lecture {
  margin-top: 30px;
}

.date {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: calc(50px + 3 * (100vw / 1280));
}

.subject {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: calc(30px + 3 * (100vw / 1280));
}

.hr {
  background: #212121;
  height: 1px;
  border-radius: 6px;
}

.form__btn {
  width: 90%;
  height: 70px;
}

</style>

