<template>
  <div>
    <a name="form"></a>
    <section class="reg__form">
      <div class="container">
        <div class="row text-center justify-content-center m-auto">
          <div class="col-12">
            <h1 class="reg__title">Для отримання доступу заповніть форму</h1>
            <p class="reg__text">Протягом тижня (залежно від навантаження) наша команда перевірить Ваші дані і
              надішле посилання для доступу до курсу на E-Mail</p>
          </div>

          <div class="row col-lg-10 col-12 m-auto">
            <form @submit.prevent class="form row m-auto" v-if="step === 1">

              <div class="form-floating mb-3 col-lg-6 col-12">
                <input
                    v-model.trim="form.name"
                    type="text"
                    class="reg__form__input form-control"
                    @blur="v$.form.name.$touch()"
                    :class="v$.form.name.$error ? 'is-invalid' : ''"
                    id="name">
                <label for="name" class="lable">Імʼя та прізвище *</label>
                <div v-if="v$.form.name.$error" class="mt-2">
                  <span class="text-danger" v-if="v$.form.name.required.$invalid">Це поле обовʼязкове</span>
                </div>
              </div>

              <div class="form-floating mb-3 col-lg-6 col-12">
                <input
                    @blur="v$.form.phone.$touch()"
                    v-model.trim="form.phone"
                    type="tel"
                    class="reg__form__input form-control"
                    :class="v$.form.phone.$error ? 'is-invalid' : ''"
                    id="phone">
                <label for="phone" class="lable">Номер телефону *</label>
                <div v-if="v$.form.phone.$error" class="mt-2">
                  <span class="text-danger" v-if="v$.form.phone.required.$invalid">Це поле обовʼязкове</span>
                </div>
              </div>

              <div class="form-floating mb-3 col-lg-6 col-12">
                <input
                    @blur="v$.form.mail.$touch()"
                    v-model.trim="form.mail"
                    type="email"
                    class="reg__form__input form-control"
                    :class="v$.form.mail.$error ? 'is-invalid' : ''"
                    id="mail">
                <label for="mail" class="lable">E-Mail *</label>
                <div v-if="v$.form.mail.$error" class="mt-2">
                  <span class="text-danger" v-if="v$.form.mail.email">Введіть правильний Email</span>
                </div>
                <div v-if="v$.form.mail.$error" class="mt-2">
                  <span class="text-danger" v-if="v$.form.mail.required.$invalid">Це поле обовʼязкове</span>
                </div>
              </div>

              <div class="col-lg-6 col-12 ">
                <select
                    @blur="v$.form.sity.$touch()"
                    v-model="form.sity"
                    class="form-select form-control"
                    :class="v$.form.sity.$error ? 'is-invalid' : ''"
                    aria-label="Default select example"
                    id="city">
                  <option value="Оберіть місто" selected disabled>Оберіть місто</option>
                  <option v-for="some_city in this.form.cities" :value="some_city">{{ some_city }}</option>
                  <option value="Інше місто">Інше місто</option>
                </select>
              </div>

              <div class="col-lg-12 col-12 my-3" v-if="form.sity === 'Дніпро'">
                <select
                    v-model="form.school"
                    class="form-select form-control"
                    aria-label="Default select example"
                    id="school">
                  <option value="Оберіть заклад освіти" disabled selected>Оберіть заклад освіти</option>
                  <option v-for="school in this.form.schools" :value="school">{{ school }}</option>
                </select>
              </div>

            </form>

            <div class="col-12 my-4" v-if="step === 2">
              <p class="reg__text">Оберіть курс</p>
            </div>

            <div class="row m-auto justify-content-center" style="text-align: left" v-if="step === 2">

              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="1" type="checkbox" disabled checked @change="changeCourseStatus(1)">
                <label class="cbx" for="1"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Математика</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="2" type="checkbox" disabled checked @change="changeCourseStatus(2)">
                <label class="cbx" for="2"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Українська мова</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="3" type="checkbox" disabled checked @change="changeCourseStatus(3)">
                <label class="cbx" for="3"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Історія України</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="4" type="checkbox" @change="changeCourseStatus(4)">
                <label class="cbx" for="4"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Біологія</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="5" type="checkbox" @change="changeCourseStatus(5)">
                <label class="cbx" for="5"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Хімія</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="6" type="checkbox" @change="changeCourseStatus(6)">
                <label class="cbx" for="6"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Фізика</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="7" type="checkbox" @change="changeCourseStatus(7)">
                <label class="cbx" for="7"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Англійська мова</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="8" type="checkbox" @change="changeCourseStatus(8)">
                <label class="cbx" for="8"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Французька мова</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="9" type="checkbox" @change="changeCourseStatus(9)">
                <label class="cbx" for="9"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Німецька мова</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="10" type="checkbox" @change="changeCourseStatus(10)">
                <label class="cbx" for="10"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Іспанська мова</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="11" type="checkbox" @change="changeCourseStatus(11)">
                <label class="cbx" for="11"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Географія</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="12" type="checkbox" @change="changeCourseStatus(12)">
                <label class="cbx" for="12"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Українська література</span></label>
              </div>

              <svg class="inline-svg">
                <symbol id="check" viewbox="0 0 12 10">
                  <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                </symbol>
              </svg>
            </div>
          </div>

          <div class="col-lg-4 col-12 m-auto">
            <button class="reg_btn" @click="changeStep" v-if="step === 1">Далі</button>
            <button class="reg_btn" @click="createUser" v-if="step === 2">Отримати курс</button>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import useVuelidate from '@vuelidate/core'
import {required, email, minValue} from '@vuelidate/validators'

export default {
  name: "Registration",
  data() {
    return {
      step: 1,
      courses: [1, 2, 3],
      form: {
        v$: null,
        name: '',
        mail: '',
        phone: '',
        cities: [
          'Дніпро',
          'Київ',
          'Харків',
          'Одеса',
          'Донецьк',
          'Запоріжжя',
          'Львів',
          'Кривий Ріг',
          'Миколаїв',
          'Севастополь',
          'Маріуполь',
          'Луганськ',
          'Вінниця',
          'Сімферополь',
          'Макіївка',
          'Херсон',
          'Чернігів',
          'Полтава',
          'Хмельницький',
          'Черкаси',
          'Чернівці',
          'Житомир',
          'Суми',
          'Рівне',
          'Горлівка',
          'Івано-Франківськ',
          'Кам\'янське',
          'Кропивницький',
          'Тернопіль',
          'Луцьк'
        ],
        sity: 'Оберіть місто',
        school: 'Оберіть заклад освіти',
        schools: [
          "КЗО ХЕЛ ДМР",
          "ДНЛІТ ДМР",
          "КЗО МЮЛ ДМР",
          "КЗО УАЛ ДМР",
          "КЗО ФЕЛ ДМР",
          "КЗО 'ЛПВФП при УМСФ' ДМР",
          "Науковий ліцей УМСФ (НЛМВ)",
          "КЗО НВК Вальдорф ДМР",
          "Ліцей №145 ДМР",
          "КЗЗСО Гімназія №2 ДМР",
          "Дніпровський ліцей №3 ДМР",
          "Ліцей №1 ДМР",
          "КЗО СЗШ №2 ДМР",
          "КЗО НВК №4 ДМР",
          "Гімназія №5 ДМР",
          "КЗО СЗШ №6 ДМР",
          "Ліцей №7 ДМР",
          "КЗО СЗШ №8 ДМР",
          "КЗО ССЗШ №9 ДМР",
          "Гімназія №10 ДМР",
          "Гімназія №11 ДМР",
          "Ліцей №12 'Інтелект' ДМР",
          "Гімназія №13 ДМР",
          "Гімназія №14 ДМР",
          "КЗО СЗШ №15 ДМР",
          "Гімназія №16 ДМР",
          "Гімназія №17 ДМР",
          "Гімназія №18 ДМР",
          "Гімназія №19 ДМР",
          "КЗО СЗШ №20 ДМР",
          "Ліцей №21 'Перспектива' ДМР",
          "Ліцей №22 ДМР",
          "Ліцей №23 'Соборний' ДМР",
          "КЗО СЗШ №24 ДМР",
          "КЗО СЗШ №25 ДМР",
          "Гімназія №26 ДМР",
          "Гімназія №27 ДМР",
          "Ліцей №28 ДМР",
          "Гімназія №29 ДМР",
          "Гімназія №30 ДМР",
          "КЗО СЗШ №31 ДМР",
          "Гімназія №32 ДМР",
          "Ліцей №33 ДМР",
          "Гімназія №34 ДМР",
          "Гімназія №35 ДМР",
          "Ліцей №36 ДМР",
          "Гімназія №37 ДМР",
          "Гімназія №39 ДМР",
          "КЗО СЗШ №40 ДМР",
          "Гімназія №41 ДМР",
          "Ліцей №42 ДМР",
          "Гімназія №43 ДМР",
          "КЗО СШ №44 ДМР",
          "КЗО СЗШ №45 ДМР",
          "КЗО СЗШ №46 ДМР",
          "КЗО СЗШ №47 ДМР",
          "КЗО НВК №48 ДМР",
          "Гімназія №49 ДМР",
          "Гімназія №50 ДМР",
          "Гімназія №51 ДМР",
          "Гімназія №52 ДМР",
          "Ліцей №53 ДМР",
          "Ліцей №54 ДМР",
          "Гімназія №55 ДМР",
          "КЗО СЗШ №56 ДМР",
          "КЗО НВК №57 ДМР",
          "КЗО СЗШ №58 ДМР",
          "КЗО НВК №59 ДМР",
          "ДТЕЛ №61 'ЕРУДИТ' ДМР",
          "Гімназія №62 ДМР",
          "Гімназія №63 ДМР",
          "Гімназія №64 ДМР",
          "Гімназія №65 ДМР",
          "КЗО НВК №66 ДМР",
          "КЗО СШ №67 ДМР",
          "Гімназія №68 ДМР",
          "КЗО СЗШ №69 ДМР",
          "Ліцей №70 ДМР",
          "КЗО НВК №71 ДМР",
          "Гімназія №72 ДМР",
          "Гімназія №73 ДМР",
          "Гімназія №74 ДМР",
          "Гімназія №75 ДМР",
          "КЗО СЗШ №76 ДМР",
          "Гімназія №77 ДМР",
          "Гімназія №78 ДМР",
          "Гімназія №79 ДМР",
          "Гімназія №80 ДМР",
          "Ліцей №81 ДМР",
          "Гімназія №82 ДМР",
          "Гімназія №83 ДМР",
          "КЗО СЗШ №84 ДМР",
          "Гімназія №85 ДМР",
          "Гімназія №86 ДМР",
          "КЗО НВК №87 ДМР",
          "Гімназія №88 ДМР",
          "Гімназія №89 ДМР",
          "КЗО СЗШ №90 ДМР",
          "Ліцей №91 ДМР",
          "Дніпровська гімназія №92 ДМР",
          "Гімназія №93 ДМР",
          "КЗО СЗШ №94 ДМР",
          "КЗО СЗШ  №96 ДМР",
          "Ліцей №97 ДМР",
          "КЗО СЗШ №98 ДМР",
          "КЗО НВК №99 ДМР",
          "КЗО НВК №100 ДМР",
          "КЗО СЗШ №101 ДМР",
          "Гімназія №102 ДМР",
          "КЗО НВК №103 ДМР",
          "Дніпровська гімназія №104 ДМР",
          "Гімназія №105 ДМР",
          "Гімназія №106 ДМР",
          "ПШ №107 ДМР",
          "Дніпровська гімназія №108 ДМР",
          "Початкова школа №108 ДМР",
          "Гімназія №109 ДМР",
          "Гімназія №110 ДМР",
          "Ліцей №111 ДМР",
          "Гімназія №112 ДМР",
          "Гімназія №113 ДМР",
          "Гімназія №114 ДМР",
          "КЗО СШ №115 ДМР",
          "Гімназія №116 ДМР",
          "КЗО СЗШ №117 ДМР",
          "Гімназія №118 ДМР",
          "КЗО СЗШ №119 ДМР",
          "Ліцей №120 ДМР",
          "Гімназія №121 ДМР",
          "КЗО НВК №122 ДМР",
          "Ліцей №123 'Тандем' ДМР",
          "КЗО СЗШ №124 ДМР",
          "Гімназія №125 ДМР",
          "КЗО ССЗШ №126 ДМР",
          "Гімназія №127 ДМР",
          "КЗО СЗШ №128 ДМР",
          "Ліцей №129 ДМР",
          "Ліцей №130 ДМР",
          "КЗО НВК №131 ДМР",
          "Гімназія №132 ДМР",
          "КЗО СЗШ №133 ДМР",
          "Ліцей №134 ДМР",
          "Гімназія №135 ДМР",
          "КЗО НВО №136 ДМР",
          "Ліцей №137 ДМР",
          "Ліцей №138 ДМР",
          "Ліцей №139 ДМР",
          "КЗО СЗШ №140 ДМР",
          "Гімназія №141 ДМР",
          "Ліцей №142 ДМР",
          "Гімназія №143 ДМР",
          "Ліцей №144 імені Леві Іцхака Шнеєрсона ДМР",
          "КЗО СЗШ №147 ДМР",
          "КЗО НВК №148 ДМР",
          "КЗО НРЦ Надія ДМР",
          "Ліцей 'Борисфен' ДОР",
          "НМЛ 'Дніпро'",
          "ДОЛІФМП",
          "КЗО БНРЦ 'Зоряний' ДОР",
          "КЗО 'Ліцей 'Сокіл' ДОР'",
          "КЗО Спеціальна школа 'ШАНС' ДОР",
          "КЗО НРЦ 'Горлиця' ДОР",
          "КЗО ДНРЦ №1 ДОР",
          "КЗО 'БНРЦ №6' ДОР'",
          "КЗО ДБНРЦ №9 ДОР",
          "КЗО 'ДНРЦ №10' ДОР'",
          "КЗО 'Спеціальна школа №12' ДОР",
          "ПО 'Ліцей Сучасної Освіти'"
        ]

      }
    }
  },
  validations() {
    return {
      form: {
        name: {required},
        mail: {required, email},
        phone: {required},
        sity: {required},
      }
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  methods: {
    changeCourseStatus(course) {
      if (!this.courses.indexOf(course)) {
        let myIndex = this.courses.indexOf(course);
        if (myIndex !== -1) {
          this.courses.splice(myIndex, 1);
        }
      } else {
        this.courses.push(course);
      }
    },
    changeStep() {
      if (this.v$.form.$errors.length === 0) {
        if (this.form.name !== '' && this.form.mail !== '' && this.form.phone !== '' && this.form.sity !== '') {
          if (this.form.mail.split('@')[1] !== 'gmail.com') {
            this.$swal({
              icon: 'error',
              title: 'Помилка',
              text: 'Вкажіть пошту одної з дозволених поштових служб: gmail.com',
            })
          } else {
            this.step++
          }
        } else {
          this.$swal.fire(
              'Ой! Щось пішло не так',
              'Перевірте чи всі обовʼязкові поля форми ви заповнили.',
              'warning'
          )
        }
      }
    },
    async createUser() {
      if (this.v$.form.$errors.length === 0) {
        if (this.form.name !== '' && this.form.mail !== '' && this.form.phone !== '' && this.courses.length !== 0 && this.form.sity !== '') {
          if (this.form.mail.split('@')[1] !== 'gmail.com') {
            this.$swal({
              icon: 'error',
              title: 'Помилка',
              text: 'Вкажіть пошту одної з дозволених поштових служб: gmail.com',
            })
          } else {
            try {
              await axios.post(
                  `${process.env.VUE_APP_API_URL}/createuser`,
                  {
                    name: this.form.name,
                    mail: this.form.mail,
                    phone: this.form.phone,
                    courses: JSON.stringify(this.courses),
                    sity: this.form.sity,
                    school: this.form.school,
                  }
              )
              this.$swal.fire(
                  {
                    icon: 'success',
                    title: 'Вітаємо!',
                    html:
                        'Заявку прийнято. Протягом тижня ми надішлемо пароль і логін для доступу до курсу на електронну пошту, ' +
                        'а поки що слідкуйте за усіма нашими оновленнями у Telegram-каналі ' +
                        ' <b><a href="https://t.me/kp_ednipro" target="_blank">@kp.ednipro</a></b>' +
                        '.'
                  }
              )
            } catch (err) {
              if (err.response.status === 409) {
                this.$swal({
                  icon: 'error',
                  title: 'Користувач з такою поштою вже існує',
                })
              } else {
                this.$swal({
                  icon: 'error',
                  title: 'Oops...',
                  text: err,
                })
              }
            }


            this.form.name = '';
            this.form.mail = '';
            this.form.phone = '';
            this.form.social = '';
            this.form.course = 0;
            this.form.sity = '';
            this.form.school = '';
          }
        } else {
          this.$swal.fire(
              'Ой! Щось пішло не так',
              'Перевірте чи всі обовʼязкові поля форми ви заповнили.',
              'warning'
          )
        }
      } else {
        this.$swal.fire(
            'Ой! Щось пішло не так',
            'Перевірте чи всі обовʼязкові поля форми ви заповнили.',
            'warning'
        )
      }
    }
  }
}
</script>

<style scoped>
.reg__form {
  font-family: DniproCity-Regular, -apple-system, sans-serif !important;
  margin-top: calc(100% / 12);
  height: 100%;
}

.reg__title {
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  margin: auto;
  max-width: 1000px;
  font-size: 70px;
  line-height: 1;
}

.form__select {
  margin-bottom: 20px;
  font-size: 24px;
}

.reg__text {
  margin: auto;
  font-size: calc(15px + 3 * (100vw / 1280));
  max-width: 800px;
  padding-top: 40px;
  line-height: 1;
}

.form {
  padding-top: calc(100% / 10);
  font-size: 14px;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
  -webkit-text-fill-color: black !important; /* цвет текста */
  color: black !important; /* цвет текста */
}

.form-control {
  font-size: 15px;
  height: 50px;
  border: solid 2px #8C9FD7;
  border-radius: 16px;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);
}

input:focus {
  box-shadow: 2px 4px 26px rgba(140, 159, 215, 0.8);
  transition: 0.2s ease-in;
}

.reg_btn {
  margin: auto;
  margin-top: 60px;
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: 24px;
  background: #8C9FD7;
  color: white;
  border-radius: 50px;
  border: 2px solid #8C9FD7;
  transition: 0.3s ease-in;
  padding: 15px 15px;
  width: 100%;
  text-transform: uppercase;
}

.reg_btn:hover {
  box-shadow: 2px 4px 26px rgba(140, 159, 215, 1);
  transition: 0.2s ease-in;
}

.social-icon img:hover {
  cursor: pointer;
}

.lable {
  font-size: calc(10px + 3 * (100vw / 1280)) !important;
  margin-left: 20px;
}

.cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  color: black;
}

.cbx:not(:last-child) {
  margin-right: 6px;
}

.cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}

.cbx span:first-child {
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  transform: scale(1);
  border: 2px solid #8C9FD7;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}

.cbx span:first-child svg {
  position: absolute;
  top: 7px;
  left: 6px;
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
  width: 20px;
  height: 20px;
  transform: scale(1.3);
}

.cbx span:last-child {
  padding-left: 8px;
  line-height: 25px;
}

.cbx:hover span:first-child {
  border-color: #8C9FD7;
}

.inp-cbx {
  position: absolute;
  visibility: hidden;
}

.inp-cbx:checked + .cbx span:first-child {
  border-color: #8C9FD7;
  animation: wave 0.4s ease;
}

.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}
</style>
