<script setup>
import IconButton from "./IconButton.vue";
import {ref, defineProps, defineEmits, onMounted} from "vue";
import Test from "./test.vue";

const props = defineProps({
  title: {type: Object, required: true}
});

const emits = defineEmits(['update', 'change', 'delete'])

const isEdit = ref(false);
const isMath = ref(false);
const questionValue = ref('');
const isChecked = ref(props.title.is_correct);
let originalValue = '';

const updateValue = (event) => {
  questionValue.value = event.target.innerText;
};

const changeField = (text) => {
  isEdit.value = true;
  questionValue.value = text;
  originalValue = text;
  isMath.value = text?.includes('$$');
};

const onComplete = () => {
  isEdit.value = false;
  emits('update', {question: questionValue.value, correct: isChecked.value});
};

const onChangeActive = () => {
  isChecked.value = !isChecked.value;
};

const onCancel = () => {
  isEdit.value = false;
  // Возвращаемся к оригинальному значению
  questionValue.value = originalValue;
};

onMounted(() => {
  questionValue.value = props.title.answer_text || '';
  isMath.value = props.title?.answer_text?.includes('$$');
})
</script>

<template>
  <div v-if="!isEdit">
    <svg v-if="title.is_correct" class="w-3.5 h-3.5 me-2 text-green-500 flex-shrink-0" aria-hidden="true"
         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
    </svg>
    <svg v-else class="w-3.5 h-3.5 me-2 text-gray-500 flex-shrink-0" aria-hidden="true"
         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
    </svg>
  </div>

  <div class="relative mr-1" v-else>
    <input type="checkbox"
           :checked="title.is_correct"
           @change="onChangeActive"
           class="peer relative appearance-none
            bg-transparent
                        w-3.5 h-3.5
                        rounded-full
                        cursor-pointer
                        checked:bg-teal-600
                        focus:outline-none
                        focus:ring-0
                        border-0
                        focus:border-0
                        focus:ring-offset-0"
           id="unchecked-circular-checkbox"/>
    <svg
        class="w-3.5 h-3.5 absolute z-[-2] top-0 me-2 text-gray-500 flex-shrink-0 peer-checked:text-green-500 peer-checked +text-teal-600"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
    </svg>
  </div>

  <div class="flex items-center">
    <h1 v-if="isMath && !isEdit" :class="{ 'editable': isEdit }">
      <vue-mathjax :formula="questionValue"></vue-mathjax>
      <br/>
    </h1>
    <h1 v-else :class="{ 'editable': isEdit }" class="flex" :contenteditable="isEdit" @input="updateValue">
      {{ questionValue }}</h1> <br/>
    <div class="flex">
      <icon-button @click="changeField(questionValue)" v-if="!isEdit"/>
      <icon-button @click="$emit('delete')" :type="4" v-if="!isEdit"/>
      <icon-button @click="onComplete" :type="2" v-if="isEdit"/>
      <icon-button @click="onCancel" :type="3" v-if="isEdit"/>
    </div>
  </div>
</template>

<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

.editable {
  border: 1px solid #ccc; /* цвет и толщина обводки */
  padding: 5px; /* отступ внутри элемента */
  display: inline-block; /* чтобы обводка не расширяла элемент на всю ширину */
}
</style>
