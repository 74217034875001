<template>
  <h1 class="m-5">Усі користувачі</h1>
  <button @click="sendAll" class="btn btn-outline-dark">Запустити розсилку</button>
  <div class="row pt-5 mb-5">
    <div class="col-lg-1">
      <h6>ID</h6>
    </div>
    <div class="col-md-2">
      <i class="bi bi-search icon"></i>
      <input class="searchInput" type="text" v-model="name" placeholder="Імʼя та Прізвище">
    </div>
    <div class="col-md-2">
      <i class="bi bi-search icon"></i>
      <input class="searchInput" type="text" v-model="mail" placeholder="Електронна пошта">
    </div>
    <div class="col-md-2">
      <i class="bi bi-search icon"></i>
      <input class="searchInput" type="text" v-model="phone" placeholder="Контактний номер">
    </div>
    <div class="col-md-1">
      <h6>Місто</h6>
    </div>
    <div class="col-md-1">
      <h6>Школа</h6>
    </div>
    <div class="col-md-3">
      <h6>Стаус</h6>
    </div>
  </div>

  <div v-for="user in this.searchHandler" v-bind:key="user.id">
    <div class="row pt-5">
      <div class="col-md-1">
        <h6>{{ user.id }}</h6>
      </div>
      <div class="col-md-2">
        <h6>{{ user.name }}</h6>
      </div>
      <div class="col-md-2">
        <h6>{{ user.mail }}</h6>
      </div>
      <div class="col-md-2">
        <h6>{{ user.phone }}</h6>
      </div>
      <div class="col-md-1">
        <h6>{{ user.sity }}</h6>
      </div>
      <div class="col-md-3" v-if="user.sity === 'Дніпро'">
        <h6 class="w-full">{{ user.school }}</h6>
      </div>
      <div class="col-md-3" v-else>
        <h6>-</h6>
      </div>
      <div class="col-md-1">
        <button v-if="user.status !== 1" type="button" class="btn btn-outline-success"
                @click="initUser(user.id, user.mail, user.name)">Активувати
        </button>
        <button v-else-if="user.status === 1" type="button" class="btn btn-success" disabled>Активовано</button>
        <button v-if="user.status === 1" type="button" class="btn btn-outline-danger mt-2"
                @click="resetPass(user.id, user.mail, user.name)">Новий пароль
        </button>
        <button v-if="user.status === 2" type="button" class="btn btn-danger mt-2" disabled>Відмовлено
        </button>
        <button v-else-if="user.status === 0" type="button" class="btn btn-outline-danger mt-2"
                @click="block(user.mail, user.name, user.id)">Відмова
        </button>
<!--        <button type="button" class="btn btn-outline-info mt-2" v-if="user.mail1 === 0 && user.status === 1"-->
<!--                @click="sendmail(user.mail, user.name, user.id)">Надіслати повідомлення</button>-->
<!--        <button type="button" class="btn btn-info mt-2" disabled v-if="user.mail1 === 1 && user.status === 1">Надіслано</button>-->
      </div>
    </div>
  </div>

  <div class="d-flex col-12">
    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group me-2" role="group" aria-label="First group" v-for="btn of count">
        <button v-if="this.active === btn.href" type="button" class="btn btn-dark mt-3" @click="loadnext(btn.href)">
          {{ btn.name }}
        </button>
        <button v-else type="button" class="btn btn-outline-dark mt-3" @click="loadnext(btn.href)">{{
            btn.name
          }}
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "user",
  data() {
    return {
      users: [],
      count: [],
      allusers: [],
      phone: '',
      name: '',
      mail: '',
      active: 'getallusers/1',
    };
  },

  async created() {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/getallusers`)
      this.users = response.data;
    } catch (err) {
      console.log(err);
    }
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/getcount`)
      this.count = response.data;
    } catch (err) {
      console.log(err);
    }
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/getallusersstats`)
      this.allusers = response.data;
    } catch (err) {
      console.log(err)
    }
  },

  methods: {
    async sendAll(){
      try {
        await axios.post(
            `${process.env.VUE_APP_API_URL}/sedInformMail`, {}, {withCredentials: true}
        )
      } catch (err) {
        this.$swal.fire(
            {
              icon: 'error',
              title: err,
            }
        )
      }
    },
    async block(mail, name, id) {
      this.allusers.find(el => el.id === id).status = 2
      try {
        this.$swal.fire(
            {
              icon: 'error',
              title: 'Користувачу відмовлено',
            }
        )
        await axios.post(
            `${process.env.VUE_APP_API_URL}/block`,
            {
              name: name,
              mail: mail,
              id: id,
            }, {withCredentials: true}
        )
      } catch (err) {
        this.$swal.fire(
            {
              icon: 'error',
              title: err,
            }
        )
      }
    },

    async sendmail(mail, name, id){
       this.allusers.find(el => el.id === id).mail1 = 1
      try {
        this.$swal.fire(
            {
              icon: 'success',
              title: 'Повідомлення надіслано',
            }
        )
        await axios.post(
            `${process.env.VUE_APP_API_URL}/sedmail`,
            {
              mail: mail,
              id: id,
            }, {withCredentials: true}
        )
      } catch (err) {
        this.$swal.fire(
            {
              icon: 'error',
              title: err,
            }
        )
      }
    },

    async loadnext(href) {
      let response = await axios.get(`${process.env.VUE_APP_API_URL}/${href}`, {withCredentials: true});
      this.users = response.data;
      this.active = href;
    },

    async initUser(id, mail, name) {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/inituser/${id}`, {
          mail: mail,
          name: name
        }, {withCredentials: true});

        this.$swal.fire(
            {
              icon: 'success',
              title: 'Активовано',
            }
        )
        this.users.find(el => el.id === id).status = 1
      } catch (err) {
        this.$swal.fire(
            {
              icon: 'error',
              title: 'Помилка!',
              text: err,
            }
        )
      }
    },
    async resetPass(id, mail, name) {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/resetuser/${id}`, {
          mail: mail,
          name: name
        }, {withCredentials: true});

        this.$swal.fire(
            {
              icon: 'success',
              title: 'Пароль користувача оновлено',
            }
        )
      } catch (err) {
        this.$swal.fire(
            {
              icon: 'error',
              title: 'Помилка!',
              text: err,
            }
        )
      }
    },
  },
  computed: {
    searchHandler() {
      if (this.name !== '') {
        return this.allusers.filter(elem => {
          return elem.name.toLowerCase().includes(this.name.toLowerCase())
        })
      } else if (this.phone !== '') {
        return this.allusers.filter(elem => {
          return elem.phone.replace(/[^0-9]/gi, '').includes(this.phone.replace(/[^0-9]/gi, ''))

        })
      } else if (this.mail !== '') {
        return this.allusers.filter(elem => {
          return elem.mail.toLowerCase().includes(this.mail.toLowerCase())
        })
      } else {
        return this.users
      }
    }
  },
}
</script>

<style scoped>

.icon{
  position: absolute;
}

h6 {
  font-family: e-Ukraine, -apple-system, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

input:active {
  border-bottom: solid 2px black;
}

input:focus::placeholder{
  color: transparent;
  transition: 0.5s;
}

input:focus {
  border-bottom: solid 2px black;
  box-shadow: none;
}

.searchInput {
  transition: 0.3s;
  text-align: center;
  background: none;
  border: none;
  font-family: e-Ukraine, -apple-system, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: black;
}
</style>
