import axios from "axios";

let header = {
    'Content-Type': 'application/json',
    'Accept': "application/json"
};

let token = localStorage.getItem('token');

if(token) header.Authorization = `Bearer ${token}`;

const instance=axios.create({
    baseURL:`${process.env.VUE_APP_API_URL}`,
    headers: header,
})

export default instance
