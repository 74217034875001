<template>
  <div v-if="data"
       class="w-full border rounded-lg my-3 p-3 shadow bg-gray-50 bg-opacity-25 hover:bg-opacity-50 transition-all duration-150 ease-linear backdrop-blur-lg">
    <div class="flex justify-between items-center hover:cursor-pointer" @click="getData(data?.lecture_id)">
      <h1 class="text-black text-xl break-words max-w-5xl">{{ data?.lecture_title }}</h1>
      <h1 class="text-black text-xl">{{ formattedDate(data?.lecture_date) }}</h1>
    </div>
    <div class="flex justify-between">
      <p class="text-black text-md mt-3">{{ data?.category_name }}</p>
      <IconButton @click="editLesson(data)"/>
    </div>

    <h1 class="my-3 text-lg font-semibold font-['DniproCity-Medium']">Питання:</h1>
    <hr class="h-px mx-auto bg-black my-1 border-0">
    <div class="my-3 overflow-y-scroll min-h-[250px] max-h-[500px] " v-if="isOpen">
      <div v-for="question in questions"
           v-if="isLoad"
           class="w-full mx-auto max-w-[1200px] border rounded-lg my-3 p-3 shadow bg-gray-50 bg-opacity-25 hover:bg-opacity-50 transition-all duration-150 ease-linear backdrop-blur-lg">
        <field :title="question" @update="updateQuestion($event, question.question_id)"/>

        <h2 class="mb-2 text-md text-gray-900 my-3">Відповіді:</h2>
        <ul class=" space-y-1 text-gray-500 list-inside">
          <li class="flex items-center min-h-14" v-for="answer in question?.answers">
            <field2 :title="answer" @delete="deleteAnswer(answer?.answer_id, question?.question_id)"
                    @update="updateAnswer($event, answer?.answer_id, question?.question_id)"/>
          </li>
          <li>
            <button
                class="text-white flex items-center  gap-2 text-s font-bold bg-blue-400 px-3 py-1.5 rounded-xl shadow mt-3"
                @click="addAnswer(question?.question_id)">Додати
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                   viewBox="0 0 256 256">
                <path fill="#ffffff"
                      d="M228 128a12 12 0 0 1-12 12h-76v76a12 12 0 0 1-24 0v-76H40a12 12 0 0 1 0-24h76V40a12 12 0 0 1 24 0v76h76a12 12 0 0 1 12 12"/>
              </svg>
            </button>
          </li>
        </ul>
        <button
            class="text-white flex items-center mx-auto gap-2 text-xl font-bold bg-[#dc2626] px-3 py-1.5 rounded-xl shadow mt-3"
            @click="deleteQuestion(question?.question_id)">Видалити питання
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256">
            <path fill="#ffffff"
                  d="M216 48H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24m-28 156H68V72h120ZM76 20A12 12 0 0 1 88 8h80a12 12 0 0 1 0 24H88a12 12 0 0 1-12-12"/>
          </svg>
        </button>
      </div>
    </div>
    <div class="flex items-center justify-center gap-4 mx-auto">
      <button
          v-if="isOpen"
          class="text-white flex items-center mx-auto gap-2 text-xl font-bold bg-green-400 px-3 py-1.5 rounded-xl shadow mt-3"
          @click="addQuestion(data?.lecture_id)">Додати питання
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 256 256">
          <path fill="#ffffff"
                d="M228 128a12 12 0 0 1-12 12h-76v76a12 12 0 0 1-24 0v-76H40a12 12 0 0 1 0-24h76V40a12 12 0 0 1 24 0v76h76a12 12 0 0 1 12 12"/>
        </svg>
      </button>
      <button
          class="text-white flex items-center mx-auto gap-2 text-xl font-bold bg-[#dc2626] px-3 py-1.5 rounded-xl shadow mt-3"
          @click="deleteOne(data?.lecture_id)">Видалити лекцію
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256">
          <path fill="#ffffff"
                d="M216 48H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24m-28 156H68V72h120ZM76 20A12 12 0 0 1 88 8h80a12 12 0 0 1 0 24H88a12 12 0 0 1-12-12"/>
        </svg>
      </button>
    </div>
  </div>

  <MainModal :isOpen="modalOpen" @update:create="updateLesson" @update:isOpen="modalOpen = $event">
    <h1 class="text-xl font-medium">
      Редагування
    </h1>

    <div class="relative mx-auto w-full">
      <input type="text" id="floating_outlined"
             v-model="editData.lecture_title"
             class="block pt-2 w-full text-sm text-gray-900 bg-transparent px-3 py-2.5 rounded border border-zinc-400 appearance-none hover:border-neutral-500 transition-all ease-linear duration-200 focus:outline-none focus:ring-0 focus:border-black peer"
             placeholder=" "/>
      <label for="floating_outlined"
             class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1">
        Назва лекції*
      </label>
    </div>
    <div class="w-full flex items-center justify-between gap-3">
      <div class="relative w-full">
        <input type="text" id="floating_outlined"
               v-model="editData.lecture_video_id"
               class="block pt-2 w-full text-sm text-gray-900 bg-transparent px-3 py-2.5 rounded border border-zinc-400 appearance-none hover:border-neutral-500 transition-all ease-linear duration-200 focus:outline-none focus:ring-0 focus:border-black peer"
               placeholder=" "/>
        <label for="floating_outlined"
               class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1">
          Відео
        </label>
      </div>
      <div class="relative w-full">
        <input type="text" id="floating_outlined"
               v-model="editData.lecture_documents"
               class="block pt-2 w-full text-sm text-gray-900 bg-transparent px-3 py-2.5 rounded border border-zinc-400 appearance-none hover:border-neutral-500 transition-all ease-linear duration-200 focus:outline-none focus:ring-0 focus:border-black peer"
               placeholder=" "/>
        <label for="floating_outlined"
               class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1">
          Додаткові матеріали
        </label>
      </div>
    </div>

    <Datapicker :date="editData?.lecture_date" @select="editData.lecture_date = $event"/>

    <div class="rounded-lg overflow-hidden" v-if="modalOpen">
      <YoutubeVue3 v-if="editData?.lecture_video_id " ref="player" :videoid="editData?.lecture_video_id"
                   class="aspect-video" :controls="0"/>
    </div>

  </MainModal>
</template>

<script setup>
import {onMounted, ref} from "vue";
import axios from "axios";
import Field from "./field.vue";
import Field2 from "./field2.vue";
import {toast} from 'vue-sonner';
import IconButton from "./IconButton.vue";
import MainModal from "./MainModal.vue";
import {YoutubeVue3} from 'youtube-vue3'
import Datapicker from "./datapicker.vue";

const props = defineProps({
  data: {type: Object, required: true},
  open: {type: Boolean, default: false}
});

const emits = defineEmits(['update', 'delete'])

const isOpen = ref(false);
const isLoad = ref(false);
const questions = ref({});
const modalOpen = ref(false);
const isDeleted = ref(false);
const editData = ref({});

const formattedDate = (d) => {
  const date = new Date(d);
  const options = {day: 'numeric', month: 'long'};
  const dateFormatter = new Intl.DateTimeFormat('uk-UA', options);
  return dateFormatter?.format(date);
};
const formattedDate2 = (d) => {
  const dateObject = new Date(d);
  dateObject.setDate(dateObject.getDate() + 1); // Добавление одного дня
  const formattedDate = dateObject.toISOString().split('T')[0];
  return formattedDate;
};
const getData = async (id) => {
  isOpen.value = !isOpen.value;

  if (isOpen.value) {
    toast.promise(
        new Promise(async (resolve, reject) => {
          try {
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/getallquestionsnew?question=${id}`);
            questions.value = response.data;
            isLoad.value = true;
            resolve(); // Разрешаем Promise после успешного выполнения запроса
          } catch (error) {
            reject(error); // Отклоняем Promise в случае ошибки
          }
        }),
        {
          loading: 'Завантаження',
          success: () => 'Успішно',
          error: () => 'Помилка'
        }
    );
  } else {
    questions.value = {};
  }
};
const updateAnswer = async (data, answer_id, question_id) => {
  toast.promise(
      async () => {
        await axios.put(`${process.env.VUE_APP_API_URL}/answer`, {
          name: data.question,
          correct: data.correct,
          id: answer_id,
        });
        const question = questions.value.find(q => q.question_id === question_id);
        if (question) {
          const answer = question.answers.find(a => a.answer_id === answer_id);
          if (answer) {
            answer.answer_text = data.question;
            answer.is_correct = data.correct;
          }
        }
      },
      {
        loading: 'Збереження відповіді',
        success: () => 'Відповідь збережено',
        error: () => 'Помилка при збереженні відповіді'
      }
  );
};
const updateQuestion = async (data, question_id) => {
  data['id'] = question_id;
  toast.promise(
      async () => {
        await axios.put(`${process.env.VUE_APP_API_URL}/question`, data);
        const question = questions.value.find(q => q.question_id === question_id);
        if (question) {
          question.question_image = data.question_image;
          question.question_name = data.question_name;
          question.question_task = data.question_task;
          question.question_text = data.question_text;
        }
      },
      {
        loading: 'Збереження питання',
        success: () => 'Питання збережено',
        error: () => 'Помилка при збереженні питання'
      }
  );
};
const deleteQuestion = async (question_id) => {
  toast.promise(
      async () => {
        await axios.delete(`${process.env.VUE_APP_API_URL}/question/${question_id}`);
        await getData();
      },
      {
        loading: 'Видалення питання',
        success: () => 'Питання видалено',
        error: () => 'Помилка при видаленні питання'
      }
  );
};
const deleteAnswer = async (answer_id, question_id) => {
  toast.promise(
      async () => {
        await axios.delete(`${process.env.VUE_APP_API_URL}/answer/${answer_id}`);
        const question = questions.value.find(q => q.question_id === question_id);
        if (question) {
          // Фильтрация массива ответов для удаления удаленного ответа
          question.answers = question.answers.filter(answer => answer.answer_id !== answer_id);
        }
      },
      {
        loading: 'Видалення відповіді',
        success: () => 'Відповідь видалено',
        error: () => 'Помилка при видаленні відповіді'
      }
  );
};
const addAnswer = async (question_id) => {
  toast.promise(
      async () => {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/answer`, {
          name: '',
          question_id: question_id,
          correct: 0
        });
        const newAnswer = {
          answer_text: "",
          question_id: question_id,
          is_correct: 0,
          answer_id: response.data.data.insertId
        };
        const question = questions.value.find(q => q.question_id === question_id);
        if (question) {
          question.answers.push(newAnswer);
        }
      },
      {
        loading: 'Додавання відповіді',
        success: () => 'Відповідь додано',
        error: () => 'Помилка при додаванні відповіді'
      }
  );
};
const editLesson = async (data) => {
  modalOpen.value = true
  editData.value = data
}
const updateLesson = async () => {
  const newData = {
    id: editData.value.lecture_id,
    publickdate: formattedDate2(editData.value.lecture_date),
    documents: editData.value.lecture_documents,
    title: editData.value.lecture_title,
    video_id: editData.value.lecture_video_id,
  }
  toast.promise(
      async () => {
        await axios.put(`${process.env.VUE_APP_API_URL}/lecture`, newData).then((res) => {
          // props.data.lecture_title = editData.value.lecture_title;
          // props.data.lecture_date = editData.value.lecture_date;
          // props.data.lecture_documents = editData.value.lecture_documents;
          // props.data.lecture_video_id = editData.value.lecture_video_id;
          emits('update')
          modalOpen.value = false
        });
      },
      {
        loading: 'Збереження питання',
        success: () => 'Питання збережено',
        error: () => 'Помилка при збереженні питання'
      }
  );
}
const addQuestion = async (lecture_id) => {
  toast.promise(
      async () => {
        const newQuestion = {
          lecture_id: lecture_id,
          name: '',
          text: '',
          image: '',
          task: '',
        }

        const newQuestion2 = {
          lecture_id: lecture_id,
          question_name: '',
          question_text: '',
          question_image: '',
          question_task: '',
          answers: [],
        }

        const response = await axios.post(`${process.env.VUE_APP_API_URL}/new/question`, newQuestion);
        newQuestion2['question_id'] = response.data.insertId
        questions.value.push(newQuestion2);
      },
      {
        loading: 'Додавання питання',
        success: () => 'Питання додано',
        error: () => 'Помилка при додаванні питання'
      }
  );
};

onMounted(async () => {
  if (props.open) {
    isOpen.value = true
    await getData(props?.data?.lecture_id)
  }
})

const deleteOne = (id) => {
  isOpen.value = false
  emits('delete', id)
}

</script>

<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

</style>