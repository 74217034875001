<template>
  <div class="dashboard text-center container">
    <h1 class="mt-5">Статистика</h1>

    <div class="row">
      <div class="col-lg-6 col-12 chart">
        <h1 class="mt-5">Статистика по статусам</h1>
        <canvas id="myChart" class="chart"></canvas>
      </div>

      <div class="col-lg-6 col-12 chart">
        <h1 class="mt-5">Статистика по предметам</h1>
        <canvas id="lessonChart" class="chart mt-5"></canvas>
      </div>

      <div class="col-lg-12 col-12 chart">
        <h1>Статистика по регисрации</h1>
        <canvas id="activChart" class="chart mt-5"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapGetters} from 'vuex'
import router from "../router";
import Chart from 'chart.js/auto'
import axios from "axios";

export default {
  name: 'Users',
  data() {
    return {
      users: [],
      active: [],
      no_active: [],
      block: [],
      math: [],
      lang: [],
      hist: [],
      all: [],
      dates: [],
      count_in_day: [],
    }
  },
  components: {},
  created() {
    if (this.user.role !== 1) {
      router.push('/')
    }
  },
  computed: {
    ...mapGetters({user: 'user/user'})
  },

  methods: {
    chertdraw() {
      const ctx = document.getElementById('myChart');
      const lessonChart = document.getElementById('lessonChart');
      const activChart = document.getElementById('activChart');

      const myChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: ['Активированные', 'Не активированные', 'Заблокировано'],
          datasets: [{
            label: 'Пользователи',
            data: [this.active.length, this.no_active.length, this.block.length],
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)',
              'rgba(255, 99, 132, 0.2)',
              'rgba(201, 203, 207, 0.2)',
            ],
            borderColor: [
              'rgba(75, 192, 192, 1)',
              'rgb(255,99,132, 1)',
              'rgb(201, 203, 207)',
            ],
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });

      const myChart2 = new Chart(lessonChart, {
        type: 'bar',
        data: {
          labels: ['Математика', 'Украинский', 'История', 'Общий'],
          datasets: [{
            label: 'Предметы',
            data: [this.math.length, this.lang.length, this.hist.length, this.all.length],
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(201, 203, 207, 0.2)'
            ],
            borderColor: [
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)',
              'rgb(201, 203, 207)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });

      const myChart3 = new Chart(activChart, {
        type: 'line',
        data: {
          labels: this.dates.slice(0, 5),
          datasets: [{
            label: 'Статистика по регистрациям',
            data: this.count_in_day.slice(0, 5),
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.5
          }]
        }
      });
    }
  },

  async mounted() {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/getallusersstats`)
      this.users = response.data;
      for (let i = 0; i < response.data.length; i++) {
        switch (response.data[i].status) {
          case 1:
            this.active.push(response.data[i])
            break;
          case 0:
            this.no_active.push(response.data[i])
            break;
          case 2:
            this.block.push(response.data[i])
            break;
        }
        switch (response.data[i].course) {
          case 1:
            this.math.push(response.data[i])
            break;
          case 2:
            this.lang.push(response.data[i])
            break;
          case 3:
            this.hist.push(response.data[i])
            break;
          case 4:
            this.all.push(response.data[i])
            break;
        }
        let m = new Date(response.data[i].reg_date);
        var curentDate = m.getUTCFullYear() + "-" + ("0" + (m.getUTCMonth() + 1)).slice(-2) + "-" + ("0" + m.getUTCDate()).slice(-2);
        this.users[i].reg_date = m.getUTCFullYear() + "-" + ("0" + (m.getUTCMonth() + 1)).slice(-2) + "-" + ("0" + m.getUTCDate()).slice(-2);
        this.dates.push(curentDate);
      }

      this.dates = Array.from(new Set(this.dates));

      for (let a = 0; a < this.dates.length; a++){
        let temp = this.users.filter(user => user.reg_date === this.dates[a])
        this.count_in_day.push(temp.length)
      }


      this.chertdraw();
    } catch (err) {
      console.log(err);
    }
  }
}

</script>


<style scoped>
h6 {
  font-family: e-Ukraine, -apple-system, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.dashboard {
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  font-size: 64px;
  padding-top: 150px;
}

.chart {
  max-height: 100vh;
}

</style>

