<template>
  <div class="container-fluid">
    <div class="row justify-content-center subjects__cards">
      <div class="col-9 m-auto">
        <div class="subjects__cards">
          <div class="profile_card d-flex align-items-center justify-content-center">
            <div class="profile row">
              <h1 class="col-12 pib my-4">{{ user.name }}</h1>
              <h2 class="col-12 school mb-5" v-if="user.school !== 'Оберіть заклад освіти'">{{ user.school }}</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 cards">
        <h1 class="subjects__title">Ваші курси</h1>
        <div id="change" class="container" v-if="modal">
          <h1 class="subjects__title">Оберіть курси</h1>

          <div class="container m-auto w-100">
            <div class="row justify-content-center  mt-5">

              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="1" type="checkbox" disabled checked @change="changeCourseStatus(1)">
                <label class="cbx" for="1"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Математика</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="2" type="checkbox" disabled checked @change="changeCourseStatus(2)">
                <label class="cbx" for="2"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Українська мова</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="3" type="checkbox" disabled checked @change="changeCourseStatus(3)">
                <label class="cbx" for="3"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Історія України</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="4" type="checkbox" :checked="user.courses.includes(4)" @change="changeCourseStatus(4)">
                <label class="cbx" for="4"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Біологія</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="5" type="checkbox" :checked="user.courses.includes(5)" @change="changeCourseStatus(5)">
                <label class="cbx" for="5"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Хімія</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="6" type="checkbox" :checked="user.courses.includes(6)" @change="changeCourseStatus(6)">
                <label class="cbx" for="6"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Фізика</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="7" type="checkbox" :checked="user.courses.includes(7)" @change="changeCourseStatus(7)">
                <label class="cbx" for="7"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Англійська мова</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="8" type="checkbox" :checked="user.courses.includes(8)" @change="changeCourseStatus(8)">
                <label class="cbx" for="8"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Французька мова</span></label>
              </div>
              <div class="col-lg-4 col-6">
                <input class="inp-cbx" id="9" type="checkbox" :checked="user.courses.includes(9)" @change="changeCourseStatus(9)">
                <label class="cbx" for="9"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Німецька мова</span></label>
              </div>
              <div class="col-lg-4 col-10">
                <input class="inp-cbx" id="10" type="checkbox" :checked="user.courses.includes(10)" @change="changeCourseStatus(10)">
                <label class="cbx" for="10"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Географія</span></label>
              </div>
              <div class="col-lg-4 col-11">
                <input class="inp-cbx" id="11" type="checkbox" :checked="user.courses.includes(11)" @change="changeCourseStatus(11)">
                <label class="cbx" for="11"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Іспанська мова</span></label>
              </div>
              <div class="col-lg-4 col-12">
                <input class="inp-cbx" id="12" type="checkbox" :checked="user.courses.includes(12)" @change="changeCourseStatus(12)">
                <label class="cbx" for="12"><span>
                <svg width="14px" height="12px">
                  <use xlink:href="#check"></use>
                </svg></span><span>Українська література</span></label>
              </div>

              <svg class="inline-svg">
                <symbol id="check" viewbox="0 0 12 10">
                  <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                </symbol>
              </svg>

              <div class="col-4 mt-4">
                <button @click="UpdateUser" class="reg_btn">Обрати</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row container justify-content-around align-items-center text-center m-auto">

          <div class="subjects__card math text-center col-lg-4 col-12" v-if="user.courses.includes(1)">
            <h2 class="card__title col-12">Математика</h2>
            <!--            <h3 class="card__text col-12">10 тижнів</h3>-->
            <img src="@/assets/math.svg" alt="math" class="card__img col-12">
            <router-link to="/allLessons/?subject=1">
              <a class="card__btn card__btn_1 d-flex align-items-center justify-content-center mt-5 col-8">До курсу</a>
            </router-link>
          </div>

          <div class="subjects__card ukr text-center col-lg-4 col-12" v-if="user.courses.includes(2)">
            <h2 class="card__title col-12">Українська мова</h2>
            <!--            <h3 class="card__text col-12">9 тижнів</h3>-->
            <img src="@/assets/ukranian.svg" alt="math" class="card__img col-12">
            <router-link to="/allLessons/?subject=2">
              <a class="card__btn card__btn_2 d-flex align-items-center justify-content-center mt-5 col-8">До курсу</a>
            </router-link>
          </div>

          <div class="subjects__card hist text-center col-lg-4 col-12" v-if="user.courses.includes(3)">
            <h2 class="card__title col-12">Історія України</h2>
            <!--            <h3 class="card__text col-12">11 тижнів</h3>-->
            <img src="@/assets/history.svg" alt="math" class="card__img col-12">
            <router-link to="/allLessons/?subject=3">
              <a href="#form" class="card__btn card__btn_3 d-flex align-items-center justify-content-center mt-5 col-8">До
                курсу</a>
            </router-link>
          </div>

          <div class="subjects__card bio text-center col-lg-4 col-12" v-if="user.courses.includes(4)">
            <h2 class="card__title col-12">Біологія</h2>
            <!--            <h3 class="card__text col-12">10 тижнів</h3>-->
            <img src="@/assets/bio.svg" alt="math" class="card__img col-12">
            <router-link to="/allLessons/?subject=4">
              <a href="#form" class="card__btn card__btn_4 d-flex align-items-center justify-content-center mt-5 col-8">До
                курсу</a>
            </router-link>
          </div>

          <div class="subjects__card chem text-center col-lg-4 col-12" v-if="user.courses.includes(5)">
            <h2 class="card__title col-12">Хімія</h2>
            <!--            <h3 class="card__text col-12">10 тижнів</h3>-->
            <img src="@/assets/him.svg" alt="math" class="card__img col-12">
            <router-link to="/allLessons/?subject=5">
              <a href="#form" class="card__btn card__btn_5 d-flex align-items-center justify-content-center mt-5 col-8">До
                курсу</a>
            </router-link>
          </div>

          <div class="subjects__card phis text-center col-lg-4 col-12" v-if="user.courses.includes(6)">
            <h2 class="card__title col-12">Фізика</h2>
            <!--            <h3 class="card__text col-12">10 тижнів</h3>-->
            <img src="@/assets/phis.svg" alt="math" class="card__img col-12">
            <router-link to="/allLessons/?subject=6">
              <a href="#form" class="card__btn card__btn_6 d-flex align-items-center justify-content-center mt-5 col-8">До
                курсу</a>
            </router-link>
          </div>

          <div class="subjects__card eng text-center col-lg-4 col-12" v-if="user.courses.includes(7)">
            <h2 class="card__title col-12">Англійська мова</h2>
            <!--            <h3 class="card__text col-12">9 тижнів</h3>-->
            <img src="@/assets/eng.svg" alt="math" class="card__img col-12">
            <router-link to="/allLessons/?subject=7">
              <a href="#form" class="card__btn card__btn_7 d-flex align-items-center justify-content-center mt-5 col-8">До
                курсу</a>
            </router-link>
          </div>

          <div class="subjects__card fra text-center col-lg-4 col-12" v-if="user.courses.includes(8)">
            <h2 class="card__title col-12">Французька мова</h2>
            <!--            <h3 class="card__text col-12">8 тижнів</h3>-->
            <img src="@/assets/fr.svg" alt="math" class="card__img col-12">
            <router-link to="/allLessons/?subject=8">
              <a href="#form" class="card__btn card__btn_8 d-flex align-items-center justify-content-center mt-5 col-8">До
                курсу</a>
            </router-link>
          </div>

          <div class="subjects__card deutch text-center col-lg-4 col-12" v-if="user.courses.includes(9)">
            <h2 class="card__title col-12">Німецька мова</h2>
            <!--            <h3 class="card__text col-12">8 тижнів</h3>-->
            <img src="@/assets/de.svg" alt="math" class="card__img col-12">
            <router-link to="/allLessons/?subject=9">
              <a href="#form" class="card__btn card__btn_9 d-flex align-items-center justify-content-center mt-5 col-8">До
                курсу</a>
            </router-link>
          </div>

          <div class="subjects__card isp text-center col-lg-4 col-12" v-if="user.courses.includes(10)">
            <h2 class="card__title col-12">Іспанська мова</h2>
            <!--          <h3 class="card__text col-12">8 тижнів</h3>-->
            <img src="@/assets/es.svg" alt="math" class="card__img col-12" style="height: 100%">
            <router-link to="/allLessons/?subject=10">
              <a href="#form" class="card__btn card__btn_10 d-flex align-items-center justify-content-center mt-5 col-8">До
                курсу</a>
            </router-link>
          </div>

          <div class="subjects__card geo text-center col-lg-4 col-12" v-if="user.courses.includes(11)">
            <h2 class="card__title col-12">Географія</h2>
            <!--          <h3 class="card__text col-12">10 тижнів</h3>-->
            <img src="@/assets/geo.svg" alt="math" class="card__img col-12">
            <router-link to="/allLessons/?subject=11">
              <a href="#form" class="card__btn card__btn_11 d-flex align-items-center justify-content-center mt-5 col-8">До
                курсу</a>
            </router-link>
          </div>

          <div class="subjects__card lit text-center col-lg-4 col-12" v-if="user.courses.includes(12)">
            <h2 class="card__title col-12">Українська література</h2>
            <!--          <h3 class="card__text col-12">10 тижнів</h3>-->
            <img src="@/assets/lit.svg" alt="math" class="card__img col-12">
            <router-link to="/allLessons/?subject=12">
              <a href="#form" class="card__btn card__btn_12 d-flex align-items-center justify-content-center mt-5 col-8">До
                курсу</a>
            </router-link>
          </div>

        </div>
      </div>

      <a href="#change">
        <button class='card__btn__change d-flex align-items-center justify-content-center mt-5 col-8' id="show-modal"
                @click="modal = true">Додати курси
        </button>
      </a>

    </div>
  </div>
  <footer/>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import axios from "axios";

export default {
  name: "cabinet",
  components: {},
  data() {
    return {
      courses: [1, 2, 3],
      modal: false,
    };
  },
  methods: {
    changeCourseStatus(course) {
      // if (course === 10 || course === 11 || course === 12) {
      //   return  this.$swal({
      //     icon: 'info',
      //     title: 'Незабаром',
      //     text: 'Курс ще в розробці'
      //   })
      // }
      if (!this.courses.indexOf(course)) {
        let myIndex = this.courses.indexOf(course);
        if (myIndex !== -1) {
          this.courses.splice(myIndex, 1);
        }
      } else {
        this.courses.push(course);
      }
    },
    async UpdateUser() {
      this.setCourses(this.courses)
      this.modal = false;
      try {
        await axios.post(
            `${process.env.VUE_APP_API_URL}/update`,
            {
              id: this.user.id,
              courses: JSON.stringify(this.courses),
            }
        )

        this.$swal.fire(
            {
              icon: 'success',
              title: 'Успішно!'
            }
        )
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: err,
        })

      }
      this.form.course = 0;
    },

    ...mapMutations({setCourses: 'user/setCourses'})
    // new1() {
    //   this.$swal.fire(
    //       {
    //         icon: 'info',
    //         title: 'Данні для підключення',
    //         text: 'Ідентифікатор конверенції: 817 2725 5859, Код доступу: 660617'
    //       }
    //   )
    // }
  },
  created() {
    if (!this.user.name) {
      router.push('/')
    }
    window.scroll(0, 0);
  },
  computed: {
    ...mapGetters({user: 'user/user'})
  }
}
</script>

<style scoped>

.pib {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: 54px;
}

.school {
  font-family: DniproCity, -apple-system, sans-serif;
  font-size: 24px;
  color: #454545;
}

.profile_card {
  margin-top: 15%;
  background: #fff;
  border-radius: 50px;
  box-shadow: 0px 4px 20px 0px rgb(0 0 0 / 10%);
}

.profile {
  text-align: center;
  margin: auto;
}


.cards {
  padding-top: 20px;
  margin-top: calc(50% / 15);
}

.subjects__title {
  text-align: center;
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  font-size: calc(40px + 16 * (100vw / 1280));
  line-height: 0.8;
}


.card__img {
  margin: auto;
  display: flex;
  max-width: 130px;
  max-height: 130px;
  height: 130px;
  height: 130px;
}

.card__title {
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  margin-top: 10px;
  height: 80px;
  line-height: 40px;
  font-size: calc(20px + 16 * (100vw / 1280));
  float: right;
}

.card__text {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  margin-bottom: 30px;
}

.subjects__card {
  margin-top: 50px;
  padding: 30px;
  background: #fff;
  border-radius: 71px;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
  transition: 1s;
  max-width: 410px;
}

.math:hover {
  box-shadow: 2px 4px 16px rgba(140, 159, 215, 1);
  transition: 0.5s;
}

.ukr:hover {
  box-shadow: 2px 4px 16px rgba(244, 142, 105, 0.65);
  transition: 0.5s;
}

.hist:hover {
  box-shadow: 2px 4px 16px rgba(249, 213, 114, 0.65);
  transition: 0.5s;
}

.bio:hover {
  box-shadow: 2px 4px 16px #A8DD89;
  transition: 0.5s;
}

.chem:hover {
  box-shadow: 2px 4px 16px rgba(98, 175, 232, 0.9);
  transition: 0.5s;
}

.phis:hover {
  box-shadow: 2px 4px 16px #C8EA3B;
  transition: 0.5s;
}

.eng:hover {
  box-shadow: 2px 4px 16px rgba(156, 142, 239, 0.69);
  transition: 0.5s;
}

.fra:hover {
  box-shadow: 2px 4px 16px rgba(228, 122, 234, 0.72);
  transition: 0.5s;
}

.deutch:hover {
  box-shadow: 2px 4px 16px #62AFE8;
  transition: 0.5s;
}

.isp:hover {
  box-shadow: 2px 4px 16px #EB765D;
  transition: 0.5s;
}

.geo:hover {
  box-shadow: 2px 4px 16px #82EFF6;
  transition: 0.5s;
}

.lit:hover {
  box-shadow: 2px 4px 16px #F1C939;
  transition: 0.5s;
}

.card__btn {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: 28px;
  padding: 10px 0;
  margin: auto;
  background: transparent;
  color: #212121;
  border-radius: 78px;
  transition: 0.3s ease-out;
}

.card__btn_1 {
  border: solid 2px #8C9FD7;
}

.card__btn__change {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: 28px;
  padding: 10px 0;
  margin: auto;
  background: transparent;
  color: #212121;
  border-radius: 78px;
  transition: 0.3s ease-out;
  width: 450px;
}

.card__btn_2 {
  border: solid 2px #F48E69;
}

.card__btn_3 {
  border: solid 2px #EFCE7D;
}

.card__btn_4 {
  border: solid 2px #A8DD89;
}

.card__btn_5 {
  border: solid 2px #62AFE8;
}

.card__btn_6 {
  border: solid 2px #C8EA3B;
}

.card__btn_7 {
  border: solid 2px #9C8EEF;
}

.card__btn_8 {
  border: solid 2px #E47AEA;
}

.card__btn_9 {
  border: solid 2px #62AFE8;
}

.card__btn_10 {
  border: solid 2px #EB765D;
}

.card__btn_11 {
  border: solid 2px #82EFF6;
}

.card__btn_12 {
  border: solid 2px #F1C838;
}

.card__btn_1:hover {
  background: #8C9FD7;
  color: white;
  transition: 0.2s ease-in;
}

.card__btn_2:hover {
  background: #F48E69;
  color: white;
  transition: 0.2s ease-in;
}

.card__btn_3:hover {
  background: #EFCE7D;
  color: white;
  transition: 0.2s ease-in;
}

.card__btn_4:hover {
  background: #A8DD89;
  color: white;
  transition: 0.2s ease-in;
}

.card__btn_5:hover {
  background: #62AFE8;
  color: white;
  transition: 0.2s ease-in;
}

.card__btn_6:hover {
  background: #C8EA3B;
  color: white;
  transition: 0.2s ease-in;
}

.card__btn_7:hover {
  background: #9C8EEF;
  color: white;
  transition: 0.2s ease-in;
}

.card__btn_8:hover {
  background: #E47AEA;
  color: white;
  transition: 0.2s ease-in;
}

.card__btn_9:hover {
  background: #62AFE8;
  color: white;
  transition: 0.2s ease-in;
}

.card__btn_10:hover {
  background: #EB765D;
  color: white;
  transition: 0.2s ease-in;
}

.card__btn_11:hover {
  background: #82EFF6;
  color: white;
  transition: 0.2s ease-in;
}

.card__btn_12:hover {
  background: #F1C838;
  color: white;
  transition: 0.2s ease-in;
}

.cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  color: black;
  margin: auto;
}

.cbx:not(:last-child) {
  margin-right: 6px;
}

.cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}

.cbx span:first-child {
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  transform: scale(1);
  border: 2px solid #8C9FD7;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}

.cbx span:first-child svg {
  position: absolute;
  top: 7px;
  left: 6px;
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
  width: 20px;
  height: 20px;
  transform: scale(1.3);
}

.cbx span:last-child {
  padding-left: 8px;
  line-height: 25px;
}

.cbx:hover span:first-child {
  border-color: #8C9FD7;
}

.inp-cbx {
  position: absolute;
  visibility: hidden;
}

.inp-cbx:checked + .cbx span:first-child {
  border-color: #8C9FD7;
  animation: wave 0.4s ease;
}

.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

.reg_btn {
  margin: auto;
  margin-top: 60px;
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: 24px;
  background: #8C9FD7;
  color: white;
  border-radius: 50px;
  border: 2px solid #8C9FD7;
  transition: 0.3s ease-in;
  padding: 15px 15px;
  width: 100%;
  text-transform: uppercase;
}

.reg_btn:hover {
  box-shadow: 2px 4px 26px rgba(140, 159, 215, 1);
  transition: 0.2s ease-in;
}
</style>
