<script setup>
import IconButton from "./IconButton.vue";
import {ref, defineProps, defineEmits, onMounted} from "vue";

const props = defineProps({
  title: {type: Object, required: true}
});

const emits = defineEmits(['update'])

const isEdit = ref(false);
const isMath = ref(false);
const questionValue = ref('');
const questionTask = ref('');
const questionText = ref('');
const questionImage = ref('');
let originalValue = '';

const updateValue = (event) => {
  questionValue.value = event.target.innerText;
};

const updateTask = (event) => {
  questionTask.value = event.target.innerText;
};

const updateText = (event) => {
  questionText.value = event.target.innerText;
};

const changeField = (text) => {
  isEdit.value = true;
  questionValue.value = text || '';
  originalValue = text || '';
  isMath.value = (text || '').includes('$$');
};

const uploadImage = async (event) => {
  const formData = new FormData();
  formData.append('files', event.target.files[0]);

  try {
    const response = await fetch('https://test.dniprorada.gov.ua/api/cdn/files/upload', {
      method: 'POST',
      body: formData
    });

    if (response.ok) {
      const data = await response.json();
      questionImage.value = 'https://test.dniprorada.gov.ua/api/cdn/files/' + data[0];
    } else {
      console.error('Image upload failed:', response.statusText);
    }
  } catch (error) {
    console.error('Image upload error:', error);
  }
};

const onComplete = () => {
  isEdit.value = false;
  emits('update', {
    name: questionValue.value,
    text: questionText.value,
    task: questionTask.value,
    image: questionImage.value
  });
};

const onCancel = () => {
  isEdit.value = false;
  questionValue.value = originalValue;
};

onMounted(() => {
  questionValue.value = props.title.question_name || '';
  questionTask.value = props.title.question_task || '';
  questionText.value = props.title.question_text || '';
  questionImage.value = props.title.question_image || '';
  isMath.value = (props.title.question_name || '').includes('$$');
});
</script>

<template>
  <div class="flex flex-col items-center gap-2">
    <h1 v-if="isEdit" class="text-lg font-medium">Заголовок питання *</h1>
    <div v-if="isMath && !isEdit" :class="{ 'editable': isEdit }">
      <vue-mathjax :formula="questionValue"></vue-mathjax>
      <br/>
    </div>
    <div v-else :class="{ 'editable': isEdit }" id="editable"
         class="flex flex-col"
         @input="updateValue">
      <h1 :contenteditable="isEdit" class="text-['DniproCity-Bold] text-3xl font-bold my-2">{{ questionValue }}</h1>
      <br/>
    </div>
    <h1 v-if="isEdit" class="text-lg font-medium">Текст питання</h1>
    <div :class="{ 'editable': isEdit }" v-if="isEdit || questionText" id="textEditable"
         class="flex flex-col p-4 bg-white max-h-[400px] overflow-y-scroll w-11/12 md:max-w-[90%]  rounded-md shadow-md"
         @input="updateText">
      <h1 class="leading-10" :contenteditable="isEdit">{{ questionText }}</h1>
      <br/>
    </div>
    <h1 v-if="isEdit" class="text-lg font-medium">Текст завдання</h1>
    <div :class="{ 'editable': isEdit }" id="taskEditable" class="flex flex-col" @input="updateTask">
      <h1 class="text-['DniproCity-Bold] text-3xl font-bold" :contenteditable="isEdit">{{ questionTask }}</h1>
      <br/>
    </div>

    <h1 v-if="isEdit" class="text-lg font-medium">Прикріпити зображення</h1>
    <img v-if="questionImage" :src="questionImage" class="editable max-h-40 object-contain mx-3"
         @click="changeImage"/>
    <div class="flex items-center justify-center max-h-40 w-1/2" v-if="isEdit">
      <label for="dropzone-file"
             class="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:border-gray-600 ">
        <div class="flex flex-col items-center justify-center p-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
            <path fill="#888888"
                  d="M228 144v64a12 12 0 0 1-12 12H40a12 12 0 0 1-12-12v-64a12 12 0 0 1 24 0v52h152v-52a12 12 0 0 1 24 0M96.49 80.49L116 61v83a12 12 0 0 0 24 0V61l19.51 19.52a12 12 0 1 0 17-17l-40-40a12 12 0 0 0-17 0l-40 40a12 12 0 1 0 17 17Z"/>
          </svg>
        </div>
        <input id="dropzone-file" type="file" class="hidden" @change="uploadImage"/>
      </label>
    </div>
    <div>
      <icon-button @click="changeField(questionValue)" v-if="!isEdit"/>
      <icon-button @click="onComplete" :type="2" v-if="isEdit"/>
      <icon-button @click="onCancel" :type="3" v-if="isEdit"/>
    </div>
  </div>
</template>

<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

.editable {
  border: 1px solid #ccc;
  padding: 5px;
  display: inline-block;
  cursor: pointer;
  @apply text-left min-w-[200px] min-h-2
}
</style>