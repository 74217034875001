<template>
  <header>
    <nav class="shadow-sm fixed-top p-3 navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">

        <div class="navbar-brand">
          <router-link to="/">
            <img src="@/assets/logo.svg" alt="Logo" style="color: black" width="150">
          </router-link>
        </div>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse justify-content-end align-items-center" id="navbarNav">

          <div class="navbar-nav  align-items-center">

            <div class="nav-item mt-2">
              <router-link to="dashboard" class="nav-link" v-if="user.role === 1">
                <h6 class="profile_link">Dashboard</h6>
              </router-link>
            </div>

            <div class="nav-item mt-2">
              <router-link to="/createquestion" class="nav-link" v-if="user.role === 1">
                <h6 class="profile_link">Створити питання</h6>
              </router-link>
            </div>


<!--            <div class="nav-item mt-2">-->
<!--              <router-link to="/createlesson" class="nav-link" v-if="user.role === 1">-->
<!--                <h6 class="profile_link">Створити лекцію</h6>-->
<!--              </router-link>-->
<!--            </div>-->

            <div class="nav-item mt-2">
              <router-link to="/newLectures" class="nav-link" v-if="user.role === 1">
                <h6 class="profile_link">Створити лекцію</h6>
              </router-link>
            </div>

            <div class="nav-item mt-2">
              <router-link to="/newusers" class="nav-link" v-if="user.role === 1">
                <h6 class="profile_link">Нові корисувачі</h6>
              </router-link>
            </div>

            <div class="nav-item mt-2">
              <router-link to="/users" class="nav-link" v-if="user.role === 1">
                <h6 class="profile_link">Усі корисувачі</h6>
              </router-link>
            </div>

            <div class="nav-item mt-2">
              <router-link to="/cabinet" class="nav-link">
                <h6 class="profile_link">{{ user.name }}</h6>
              </router-link>
            </div>

            <div class="nav-item">
              <router-link to="Login" class="nav-link m-auto">
                <button v-if="user.name" class="btn btn-outline-dark py-2 px-5" @click="this.logout">Вийти</button>
                <button v-else class="btn btn-outline-dark py-2 px-5">Увійти</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>

  <router-view/>

  <section class="footer">
    <div class="hr w-100 mt-5"></div>
    <div class="container mb-5">

      <div class="row footer-items justify-content-center align-items-center">

        <div class="footer-item col-lg-3 col-md-6 col-12">
          <img src="@/assets/logo.svg" alt="logo" width="200">
        </div>

        <div class="footer-item col-lg-3 col-md-6 col-12">
          <a class="social-icon" href="https://www.facebook.com/kpednipro" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
              <g fill="none" fill-rule="evenodd">
                <path
                    d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"/>
                <path fill="#000000"
                      d="M4 12a8 8 0 1 1 9 7.938V14h2a1 1 0 1 0 0-2h-2v-2a1 1 0 0 1 1-1h.5a1 1 0 1 0 0-2H14a3 3 0 0 0-3 3v2H9a1 1 0 1 0 0 2h2v5.938A8.001 8.001 0 0 1 4 12Zm8 10c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10Z"/>
              </g>
            </svg>
          </a>
          <a class="social-icon" href="https://t.me/kp_ednipro" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
              <g fill="none" fill-rule="evenodd">
                <path
                    d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"/>
                <path fill="#000000"
                      d="M21.84 6.056a1.5 1.5 0 0 0-2.063-1.626l-17.1 7.2c-1.192.502-1.253 2.226 0 2.746a56.46 56.46 0 0 0 3.774 1.418c1.168.386 2.442.743 3.463.844c.279.334.63.655.988.95c.547.45 1.205.913 1.885 1.357c1.362.89 2.873 1.741 3.891 2.295c1.217.66 2.674-.1 2.892-1.427l2.27-13.757ZM4.594 12.992l15.124-6.367l-2.118 12.84c-.999-.543-2.438-1.356-3.72-2.194a19.982 19.982 0 0 1-1.709-1.229a7.962 7.962 0 0 1-.426-.374l3.961-3.96a1 1 0 0 0-1.414-1.415L9.955 14.63c-.734-.094-1.756-.366-2.878-.736a48.89 48.89 0 0 1-2.482-.902Z"/>
              </g>
            </svg>
          </a>
          <a class="social-icon" href="https://instagram.com/ednipro?igshid=MTYzYml0bWhudzVneQ==" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24">
              <svg fill="none" fill-rule="evenodd">
                <path
                    d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"/>
                <path fill="#000000"
                      d="M16 3a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8a5 5 0 0 1 5-5h8Zm0 2H8a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3Zm-4 3a4 4 0 1 1 0 8a4 4 0 0 1 0-8Zm0 2a2 2 0 1 0 0 4a2 2 0 0 0 0-4Zm4.5-3.5a1 1 0 1 1 0 2a1 1 0 0 1 0-2Z"/>
              </svg>
            </svg>
            </a>
        </div>

        <div class="footer-item col-lg-3 col-md-6 col-12">
          <p class="footer__text"><a href="mailto:kp.edipro@gmail.com">kp.ednipro@gmail.com</a></p>
        </div>

        <div class="footer-item col-lg-3 col-md-6 col-12">
          <p class="footer__text">&copy; Всі права захищені</p>
        </div>

        <div class="mt-5 col-12 row footer__text_2">

          <div class="col-md-4 col-12">
            <a href="tel:+380678769449">(067) 876-94-49</a>
          </div>
          <div class="col-md-4 col-12">
            <a href="tel:+380508769449">(063) 876-94-49</a>
          </div>
          <div class="col-md-4 col-12">
            <a href="tel:+380508769449">(050) 876-94-49</a>
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  methods: {
    logout() {
      this.setUser({});
      localStorage.clear();
    },
    ...mapMutations({'setUser': 'user/setUser'})
  },
  computed: {
    ...mapGetters({user: 'user/user'})
  }
}
</script>

<style>

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, с 1999 года*/
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.MathJax_Display {
  margin: 10px !important;
  width: auto !important;
}

.MathJax_Preview, .MathJax_Display, .MathJax {
  display: inline-block !important;
}

*, *::before, *::after {
  box-sizing: border-box;
}

@font-face {
  font-family: "DniproCity-Regular";
  src: url("./assets/fonts/DniproCity-Regular.otf") format("opentype");
}

@font-face {
  font-family: "DniproCity-Bold";
  src: url("./assets/fonts/DniproCity-Bold.otf") format("opentype");
}

@font-face {
  font-family: "DniproCity-Medium";
  src: url("./assets/fonts/DniproCity-Medium.otf") format("opentype");
}

.router-link-exact-active {
  text-decoration: underline !important;
}

body {
  background: #f3f1f6;
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  line-height: 1;
  letter-spacing: 0.5px;
}

.context {
  width: 100%;
  position: absolute;
  top: 0;
}

.area {
  position: fixed;
  z-index: -99999;
  width: 100%;
  height: 100%;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(211, 222, 244, 0.7);
  animation: animate 25s linear infinite;
  bottom: -150px;

}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}


@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }

}

a, a:hover, a:active {
  text-decoration: none;
  color: #212121;
}

nav {
  z-index: 9999 !important;
}

.profile_link {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: 18px;
  margin-right: 15px;
  transition: 5s ease-in;
}

.profile_link:hover {
  transition: 5s ease-in;
  text-decoration: underline;
}

.navbar-brand {
  padding-left: 20px;
  padding-top: 10px;
}

@media only screen and (max-width: 767px) {
  h1, h2 {
    font-size: 32px !important;
  }

  p, label {
    font-size: 24px !important;
  }

}

@media only screen and (max-width: 380px) {
  .home {
    padding-top: 100px;
  }
}


:active, :hover, :focus, :visited {
  outline: 0;
  outline-offset: 0;
}

.footer {
  padding-top: 80px;
  text-align: center;
}

.social-icon img:hover {
  cursor: pointer;
}

.social-icon {
  margin-right: 20px;
  font-size: 32px;
}

.hr {
  background: #212121;
  height: 1px;
  border-radius: 6px;
}

.footer {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  color: #212121;
}

.footer-item {
  margin-top: 5%;
}

.footer__text {
  font-size: 24px;
}

.footer__text_2 {
  font-size: 20px;
}
</style>
