<template>
  <div>
    <h1 class="pt-5 text-center pairs_title">Автори платформи</h1>
    <div class="container pairs d-flex justify-content-center">
      <div class=" row pairs-container ">
        <div class="col-lg-3 col-12 m-auto pt-5 pb-5">
          <img src="@/assets/logo_light.svg" alt="КП єДніпро" class="logo">
        </div>
        <div class="col-lg-4 col-12 m-auto pt-5 pb-5">
          <img src="@/assets/osv.png" alt="Управління освіти" class="logo">
        </div>
        <div class="col-lg-3 col-12 m-auto pt-5 pb-5">
          <img src="@/assets/ku.png" alt="КП єДніпро" class="logo">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pairs"
}
</script>

<style scoped>

.pairs_title {
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  font-size: 70px;
  font-weight: 600;
  line-height: 0.8;
}

.pairs {
  margin-top: 100px;
  background: #8C9FD7;
  border-radius: 100px;
}

.pairs-container{
  width: 90%;
  min-height: 200px;
}

.logo {
  width: 85%;
}

</style>
