<script setup>
import {ref, onMounted} from "vue";
import axios from "axios";
import questions from "../components/questions.vue";
import {toast, Toaster} from "vue-sonner";
import Datapicker from "../components/datapicker.vue";
import MainModal from "../components/MainModal.vue";

const lectures = ref([]);
const currentPage = ref(0);
const selected = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
const loading = ref(false);
const modalOpen = ref(false);
const lecture_title = ref('')
const lecture_video_id = ref('')
const lecture_documents = ref('')
const lecture_date = ref('')
const lecture_category = ref(1)
const lecture_id = ref()
const lecture_New = ref()


// Получение всех лекций
const getAllLectures = async () => {
  if (loading.value) return;

  loading.value = true;
  try {
    const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/getalllecturesnew?categories=${JSON.stringify(
            selected.value
        )}&page=${currentPage.value}`
    );
    lectures.value = [...lectures.value, ...response.data];
    currentPage.value++;
  } catch (e) {
    console.error("Error fetching lectures: ", e);
  } finally {
    loading.value = false;
  }
};

// Изменение статуса выбранных категорий
const changeStatus = async (category) => {
  const index = selected.value.indexOf(category);
  if (index === -1) {
    selected.value.push(category);
  } else {
    selected.value.splice(index, 1);
  }

  currentPage.value = 1;
  lectures.value = [];
  await getAllLectures();
};

// Обработчик прокрутки страницы
const handleScroll = () => {
  const {scrollTop, clientHeight, scrollHeight} = document.documentElement;
  if (scrollTop + clientHeight >= scrollHeight) {
    getAllLectures();
  }
};

// Жизненный цикл компонента
onMounted(async () => {
  await getAllLectures();
  window.addEventListener("scroll", handleScroll);
});

const formattedDate2 = (d) => {
  const dateObject = new Date(d);
  dateObject.setDate(dateObject.getDate() + 1); // Добавление одного дня
  const formattedDate = dateObject.toISOString().split('T')[0];
  return formattedDate;
};

const createLesson = async () => {
  toast.promise(
      async () => {
        const newLecture = {
          title: lecture_title.value,
          category: lecture_category.value,
          video_id: lecture_video_id.value,
          documents: lecture_documents.value,
          publickdate: formattedDate2(lecture_date.value),
        }

        const response = await axios.post(`${process.env.VUE_APP_API_URL}/new/lecture`, newLecture);
        newLecture['lecture_id'] = response.data.insertId
        lecture_id.value = response.data.insertId
        newLecture['lecture_date'] = formattedDate2(lecture_date.value),
            lectures.value.push(newLecture);

        const newLecture2 = {
          lecture_title: lecture_title.value,
          lecture_category: lecture_category.value,
          lecture_video_id: lecture_video_id.value,
          lecture_documents: lecture_documents.value,
          lecture_date: formattedDate2(lecture_date.value),
          lecture_id: response.data.insertId
        }

        lecture_New.value = newLecture2;
      },
      {
        loading: 'Додавання питання',
        success: () => 'Питання додано',
        error: () => 'Помилка при додаванні питання'
      }
  );

  // modalOpen.value = false
}

const deleteLection = async () => {
  loading.value = true
  toast.promise(
      async () => {
        const response = await axios.delete(`${process.env.VUE_APP_API_URL}/lecture/${lecture_id.value}`);
        loading.value = true
      },
      {
        loading: 'Оновлення',
        success: () => 'Перелік оновлено',
        error: () => 'Помилка'
      }
  );

  currentPage.value = 0
  await getAllLectures()
}
const deleteOne = async (lecture) => {
  lecture_id.value = lecture
  await deleteLection()
}

const save = async () => {
  lecture_title.value = ''
  lecture_video_id.value = ''
  lecture_documents.value = ''
  lecture_date.value = ''
  lecture_category.value = 1
  lecture_id.value = ''
  modalOpen.value = false
  await getAllLectures()
}

const closModel = async () => {
  if (!lecture_id.value) {
    lecture_title.value = ''
    lecture_video_id.value = ''
    lecture_documents.value = ''
    lecture_date.value = ''
    lecture_category.value = 1
    lecture_id.value = ''
    modalOpen.value = false
    return
  }

  await deleteLection()

  lecture_title.value = ''
  lecture_video_id.value = ''
  lecture_documents.value = ''
  lecture_date.value = ''
  lecture_category.value = 0
  lecture_id.value = ''
  modalOpen.value = false
}
</script>

<template>
  <div class="w-full min-h-14 mt-[150px] container">
    <div
        class="sticky z-10 w-full grid grid-cols-12 gap-3 justify-around items-center bg-white top-[100px] p-4 px-5 text-center rounded-full text-black shadow-md border-2 border-purple-200">
      <div class="col-span-2 flex items-center">
        <input id="checkbox-1" type="checkbox" value="1"
               :checked="selected.includes(1)"
               :disabled="selected.length === 1 && selected.includes(1)"
               @change="changeStatus(1)"
               class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-0">
        <label for="checkbox-1" class="ms-2 text-sm font-medium text-black">Математика</label>
      </div>
      <div class="col-span-2 flex items-center">
        <input id="checkbox-2" type="checkbox" value="2"
               :checked="selected.includes(2)"
               @change="changeStatus(2)"
               :disabled="selected.length === 1 && selected.includes(2)"
               class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-0">
        <label for="checkbox-2" class="ms-2 text-sm font-medium text-black">Українська мова</label>
      </div>
      <div class="col-span-2 flex items-center">
        <input id="checkbox-3" type="checkbox" value="3"
               :checked="selected.includes(3)"
               @change="changeStatus(3)"
               :disabled="selected.length === 1 && selected.includes(3)"
               class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-0">
        <label for="checkbox-3" class="ms-2 text-sm font-medium text-black">Історія України</label>
      </div>
      <div class="col-span-2 flex items-center">
        <input id="checkbox-4" type="checkbox" value="4"
               :checked="selected.includes(4)"
               @change="changeStatus(4)"
               :disabled="selected.length === 1 && selected.includes(4)"
               class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-0">
        <label for="checkbox-4" class="ms-2 text-sm font-medium text-black">Біологія</label>
      </div>
      <div class="col-span-2 flex items-center">
        <input id="checkbox-5" type="checkbox" value="5"
               :checked="selected.includes(5)"
               @change="changeStatus(5)"
               :disabled="selected.length === 1 && selected.includes(5)"
               class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-0">
        <label for="checkbox-5" class="ms-2 text-sm font-medium text-black">Хімія</label>
      </div>
      <div class="col-span-2 flex items-center">
        <input id="checkbox-6" type="checkbox" value="6"
               :checked="selected.includes(6)"
               @change="changeStatus(6)"
               :disabled="selected.length === 1 && selected.includes(6)"
               class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-0">
        <label for="checkbox-6" class="ms-2 text-sm font-medium text-black">Фізика</label>
      </div>
      <div class="col-span-2 flex items-center">
        <input id="checkbox-7" type="checkbox" value="7"
               :checked="selected.includes(7)"
               @change="changeStatus(7)"
               :disabled="selected.length === 1 && selected.includes(7)"
               class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-0">
        <label for="checkbox-7" class="ms-2 text-sm font-medium text-black">Англійська мова</label>
      </div>
      <div class="col-span-2 flex items-center">
        <input id="checkbox-8" type="checkbox" value="8"
               :checked="selected.includes(8)"
               @change="changeStatus(8)"
               :disabled="selected.length === 1 && selected.includes(8)"
               class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-0">
        <label for="checkbox-8" class="ms-2 text-sm font-medium text-black">Французька мова</label>
      </div>
      <div class="col-span-2 flex items-center">
        <input id="checkbox-9" type="checkbox" value="9"
               :checked="selected.includes(9)"
               @change="changeStatus(9)"
               :disabled="selected.length === 1 && selected.includes(9)"
               class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-0">
        <label for="checkbox-9" class="ms-2 text-sm font-medium text-black">Німецька мова</label>
      </div>
      <div class="col-span-2 flex items-center">
        <input id="checkbox-10" type="checkbox" value="10"
               :checked="selected.includes(10)"
               @change="changeStatus(10)"
               :disabled="selected.length === 1 && selected.includes(10)"
               class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-0">
        <label for="checkbox-10" class="ms-2 text-sm font-medium text-black">Іспанська мова</label>
      </div>
      <div class="col-span-2 flex items-center">
        <input id="checkbox-11" type="checkbox" value="11"
               :checked="selected.includes(11)"
               @change="changeStatus(11)"
               :disabled="selected.length === 1 && selected.includes(11)"
               class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-0">
        <label for="checkbox-11" class="ms-2 text-sm font-medium text-black">Географія</label>
      </div>
      <div class="col-span-2 flex items-center">
        <input id="checkbox-12" type="checkbox" value="12"
               :checked="selected.includes(12)"
               @change="changeStatus(12)"
               :disabled="selected.length === 1 && selected.includes(12)"
               class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-0">
        <label for="checkbox-12" class="ms-2 text-sm font-medium text-black">Українська література</label>
      </div>
    </div>

    <div class="w-full text-center  mt-3">
      <button @click="modalOpen = true"
              class="flex mx-auto items-center font-medium text-white px-3 py-2 rounded-full text-md bg-black">
          <span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                     viewBox="0 0 256 256">
            <path fill="#ffffff"
                  d="M228 128a12 12 0 0 1-12 12h-76v76a12 12 0 0 1-24 0v-76H40a12 12 0 0 1 0-24h76V40a12 12 0 0 1 24 0v76h76a12 12 0 0 1 12 12"/></svg></span>
        Додати лекцію
      </button>
    </div>

    <div v-if="!loading">
      <questions @delete="deleteOne($event)" v-for="lecture in lectures" :data="lecture"/>
    </div>
    <div v-else>
      <div class="w-full mx-auto mt-[100px] text-center" v-show="loading">
        <h1 class="w-full flex justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24">
            <circle cx="4" cy="12" r="3" fill="#888888">
              <animate id="svgSpinners3DotsBounce0" attributeName="cy" begin="0;svgSpinners3DotsBounce1.end+0.25s"
                       calcMode="spline" dur="0.6s" keySplines=".33,.66,.66,1;.33,0,.66,.33" values="12;6;12"/>
            </circle>
            <circle cx="12" cy="12" r="3" fill="#888888">
              <animate attributeName="cy" begin="svgSpinners3DotsBounce0.begin+0.1s" calcMode="spline" dur="0.6s"
                       keySplines=".33,.66,.66,1;.33,0,.66,.33" values="12;6;12"/>
            </circle>
            <circle cx="20" cy="12" r="3" fill="#888888">
              <animate id="svgSpinners3DotsBounce1" attributeName="cy" begin="svgSpinners3DotsBounce0.begin+0.2s"
                       calcMode="spline" dur="0.6s" keySplines=".33,.66,.66,1;.33,0,.66,.33" values="12;6;12"/>
            </circle>
          </svg>
        </h1>
      </div>
    </div>
  </div>

  <MainModal :with-cont="false" :isOpen="modalOpen" @update:create="createLesson" @update:isOpen="closModel">
    <h1 class="text-xl font-medium">
      Створення
    </h1>

    <div v-if="!lecture_id" class="relative mx-auto w-full">
      <input type="text" id="floating_outlined"
             v-model="lecture_title"
             class="block pt-2 w-full text-sm text-gray-900 bg-transparent px-3 py-2.5 rounded border border-zinc-400 appearance-none hover:border-neutral-500 transition-all ease-linear duration-200 focus:outline-none focus:ring-0 focus:border-black peer"
             placeholder=" "/>
      <label for="floating_outlined"
             class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1">
        Назва лекції*
      </label>
    </div>
    <div v-if="!lecture_id" class="w-full flex items-center justify-between gap-3">
      <div class="relative w-full">
        <input type="text" id="floating_outlined"
               v-model="lecture_video_id"
               class="block pt-2 w-full text-sm text-gray-900 bg-transparent px-3 py-2.5 rounded border border-zinc-400 appearance-none hover:border-neutral-500 transition-all ease-linear duration-200 focus:outline-none focus:ring-0 focus:border-black peer"
               placeholder=" "/>
        <label for="floating_outlined"
               class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1">
          Відео
        </label>
      </div>
      <div v-if="!lecture_id" class="relative w-full">
        <input type="text" id="floating_outlined"
               v-model="lecture_documents"
               class="block pt-2 w-full text-sm text-gray-900 bg-transparent px-3 py-2.5 rounded border border-zinc-400 appearance-none hover:border-neutral-500 transition-all ease-linear duration-200 focus:outline-none focus:ring-0 focus:border-black peer"
               placeholder=" "/>
        <label for="floating_outlined"
               class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1">
          Додаткові матеріали
        </label>
      </div>
    </div>
    <Datapicker v-if="!lecture_id" :date="lecture_date" @select="lecture_date = $event"/>
    <div class="rounded-lg overflow-hidden" v-if="modalOpen && !lecture_id">
      <YoutubeVue3 v-if="lecture_video_id " ref="player" :videoid="lecture_video_id"
                   class="aspect-video" :controls="0"/>
    </div>

    <div class="overflow-y-scroll w-full">
      <questions :open="true" v-if="lecture_id" :data="lecture_New"/>
    </div>

    <template #cont>
      <button
          v-if="!lecture_id"
          class="text-white flex items-center gap-2 text-s font-bold bg-green-400 px-3 py-1.5 rounded-2xl shadow mt-3"
          @click="createLesson">Далі
      </button>
      <button
          v-else
          class="text-white flex items-center gap-2 text-s font-bold bg-green-400 px-3 py-1.5 rounded-2xl shadow mt-3"
          @click="save()">Зберегти
      </button>
    </template>
  </MainModal>
  <Toaster theme="light" position="top-right" richColors/>
</template>

<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>