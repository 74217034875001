import axios from "axios";


export const getLecture = {
    state() {
        return {results: {}}
    },
    mutations: {
        setResponse(state, data) {
            state.results = data
        }
    },
    actions: {
        async lectureQuery({commit}, query) {
            await axios.get(`${process.env.VUE_APP_API_URL}/getlecture/${query}`)
                .then((response) => {
                    commit('setResponse', response.data);
                }).catch((err)=>{
                    commit('setResponse', {title: "Error"});
                })
        }
    },
    getters: {
        results(state) {
            return state.results
        }
    },
    namespaced: true
}
