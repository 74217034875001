<template>
  <div class="container login-form d-flex d-flex align-items-center text-center col-lg-6 col-12">
    <div class="form-content">
      <div class="form m-auto">
        <h1 class="pt-5">Створити лекцію</h1>
        <div class="form-floating ">
          <form @submit.prevent class="form w-50">

            <div class="form-floating">
              <input
                  v-bind:value="title"
                  @input="title = $event.target.value"
                  type="text"
                  class="form-control"
                  id="mail">
              <label for="mail" class="lable">Назва лекції *</label>
            </div>

            <div class="m-auto">
              <select
                  v-bind:value="category"
                  v-model="category"
                  class="form-control"
                  id="category">
                <option value="1">Математика</option>
                <option value="2">Українська мова</option>
                <option value="3">Історія України</option>
                <option value="4">Біологія</option>
                <option value="5">Хімія</option>
                <option value="6">Фізика</option>
                <option value="7">Англійська мова</option>
                <option value="8">Французька мова</option>
                <option value="9">Німецька мова</option>
                <option value="10">Іспанська мова</option>
                <option value="11">Географія</option>
                <option value="12">Українська література</option>
              </select>
            </div>

            <div class="form-floating">
              <input
                  v-bind:value="publickdate"
                  @input="publickdate = $event.target.value"
                  type="date"
                  class="form-control"
                  id="publickdate">
              <label for="publickdate" class="lable">Дата публікації *</label>
            </div>

            <div class="form-floating">
              <input
                  v-bind:value="video_id"
                  @input="video_id = $event.target.value"
                  type="text"
                  class="form-control"
                  id="video_id">
              <label for="video_id" class="lable">Video_id *</label>
            </div>

            <div class="form-floating">
              <input
                  v-bind:value="documents"
                  @input="documents = $event.target.value"
                  type="text"
                  class="form-control"
                  id="documents">
              <label for="documents" class="lable">Додаткові матеріали</label>
            </div>

<!--            <div class="form-floating">-->
<!--              <div class="mb-3">-->
<!--                <label for="formFile" class="input-file-label">Зображення</label>-->
<!--                <input class="form-control file" accept="image/*" @change="onFileLoad"-->
<!--                       type="file"-->
<!--                       id="formFile">-->
<!--              </div>-->
<!--            </div>-->

            <button class="btn btn-outline-dark form__btn mb-5" @click="create()">Створити</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../router";
import {mapGetters} from 'vuex'
import axios from "axios";

export default {
  name: "createlesson",
  data() {
    return {
      title: '',
      category: 1,
      video_id: '',
      publickdate: '',
      documents: '',
    };
  },
  methods: {
    async create() {
      try {
        await axios.post(
            `${process.env.VUE_APP_API_URL}/createlesson`,
            {
              category: this.category,
              publickdate: this.publickdate,
              title: this.title,
              video_id: this.video_id,
              documents: this.documents,
            }, {withCredentials: true}
        )
        this.$swal.fire(
            {
              icon: 'success',
              title: 'Успешно!',
            }
        )
      } catch (err) {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: err,
        })
      }
    },
    computed: {
      ...
          mapGetters({user: 'user/user'})
    }
    ,
    created() {
      if (this.user.role !== 1) {
        router.push('/')
      }
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}

.input-file-label {
  font-size: 32px;
  margin-top: 30px;
  width: 100%;
  padding: 10px 0;
  border-radius: 50px;
  border: solid 2px #8C9FD7;
  transition: 0.4s;
}

.input-file-label:hover {
  color: white;
  background: #8C9FD7;
  cursor: pointer;
  transition: 0.3s;
}

.login-form {
  height: 100vh;
}

.form-content {
  border-radius: 50px;
  margin-top: calc(100% / 10);
  width: 100%;
  box-shadow: 0px 4px 20px 0px rgb(0 0 0 / 10%);
}

.lable {
  font-size: calc(10px + 3 * (100vw / 1280)) !important;
  margin-left: 10px;
}

.form {
  margin: auto;
  background: #fff;
  border-radius: 50px;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
  -webkit-text-fill-color: black !important; /* цвет текста */
  color: black !important; /* цвет текста */
}

.form-control {
  height: 60px;
  margin-top: 60px;
  box-shadow: none !important;
  border-radius: 50px;
  border: 2px solid #8C9FD7;
}

h1 {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: 56px;
}

.form__btn {
  margin-top: 30px;
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: calc(20px + 10 * (100vw / 1280));
  background: #8C9FD7;
  color: white;
  border-radius: 50px;
  border: 2px solid #8C9FD7;
  transition: 0.3s ease-in;
  padding: 1px 50px;
  text-transform: uppercase;
}
</style>
