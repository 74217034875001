<template>
  <section class="home container d-flex align-items-center">
    <div class="home__content row">

      <div class="col-12 col-lg-6 head">
        <div class="head__img d-flex">
          <i class="before__text"></i>
          <i class="before__circle"></i>
          <i class="before__circle__2"></i>
          <i class="before__line__1"></i>
          <img class="head_image" src="@/assets/header_image.png" alt="boy">
        </div>
      </div>

      <div class="col-lg-6 col-12 main__content">
        <h1 class="home__title">Національне мультипредметне тестування</h1>
        <p class="home__text">онлайн-платформа підготовки учнів України</p>
        <div class="text-center text-md-start">
        <a href="#subjects" class="home__btn">Обрати курс</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>
.home {
  margin-top: calc(100% / 20);
  margin-bottom: 100px;
  height: 100vh;
}

.head__img {
  position: relative;
  max-width: 100%;
}

.head_image {
  max-width: 100%;
  border-radius: 50px;
}

.before__text {
  z-index: 20;
}

.before__text:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "ОСВІТА 2024";
  background: #1d2c65;
  padding: 2.5% 5%;
  color: white;
  border-radius: 50px;
  transform: rotate(-15deg);
  font-family: DniproCity-Regular, -apple-system, sans-serif, -apple-system, sans-serif;
  font-size: calc(24px + 16 * (100vw / 1280));
  margin-left: calc(100% / 2.5);
  margin-top: calc(8% * -1);
}

.before__circle:before {
  content: "";
  z-index: 100;
  background: #ffffff;
  width: calc(100% / 4);
  height: 35%;
  border-radius: 50%;
  position: absolute;
  margin-top: 45%;
  margin-left: 20px;
}

.before__circle__2:before {
  content: "";
  z-index: -1;
  background: #f7d984;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  margin-top: 2.5%;
  margin-left: -25px;
}

.before__line__1:before{
  content: "";
  z-index: -1;
  background: #e2e2e2;
  width: calc(100% / 3);
  height: 10%;
  border-radius: 50px;
  position: absolute;
  transform: rotate(15deg);
  margin-top: 10%;
  margin-left: calc(100% - 150px);
}

.main__content {
  margin-top: calc(100% / 20);
  padding-left: calc(100% / 20);
}

.home__title {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: 70px;
  font-weight: 600;
  line-height: 1;
}

.home__text {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: calc(9px + 16 * (100vw / 1280));
  margin-top: 10px;
  line-height: 1;
  letter-spacing: 2px;
  margin-bottom: 50px;
  max-width: 450px;
}

.home__btn {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: black;
  background: #f7d984;
  border-radius: 50px;
  transition: 0.3s ease-out;
  padding: 15px 40px;
  text-transform: uppercase;
}

.home__btn:hover{
  box-shadow: 2px 4px 26px rgba(247, 217, 132, 1);
  transition: 0.2s ease-in;
}

@media only screen and (min-width: 400px) and (max-width: 991px) {
  .head{
    padding-top: 300px;
  }
}

@media only screen and (max-height: 400px) {
  .head{
    padding-top: 400px
  }
}
</style>
