import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueMathjax from 'vue-mathjax-next';
import {MotionPlugin} from '@vueuse/motion';

createApp(App)
    .use(store)
    .use(router)
    .use(VueSweetalert2)
    .use(VueMathjax)
    .use(MotionPlugin)
    .mount('#app')
