<template>
  <div>
    <a name="subjects"></a>
    <section class="subjects__cards container text-center">

      <h1 class="subjects__title">Оберіть курс</h1>

      <div class="row subjects__cards__layout justify-content-lg-between justify-content-center">

        <div class="subjects__card col-lg-4 col-12 text-center">
          <h2 class="card__title col-12">Математика</h2>
<!--          <h3 class="card__text col-12"></h3>-->
          <img src="@/assets/math.svg" alt="math" class="card__img col-12">
          <a href="#form" class="card__btn card__btn_1 d-flex align-items-center justify-content-center mt-5 col-8">Отримати
            курс</a>
        </div>

        <div class="subjects__card col-lg-4 col-12 text-center">
          <h2 class="card__title col-12">Українська мова</h2>
<!--          <h3 class="card__text col-12"></h3>-->
          <img src="@/assets/ukranian.svg" alt="math" class="card__img col-12">
          <a href="#form" class="card__btn card__btn_2 d-flex align-items-center justify-content-center mt-5 col-8">Отримати
            курс</a>
        </div>

        <div class="subjects__card col-lg-4 col-12 text-center">
          <h2 class="card__title col-12">Історія України</h2>
<!--          <h3 class="card__text col-12">11 тижнів</h3>-->
          <img src="@/assets/history.svg" alt="math" class="card__img col-12">
          <a href="#form" class="card__btn card__btn_3 d-flex align-items-center justify-content-center mt-5 col-8">Отримати
            курс</a>
        </div>

        <div class="subjects__card col-lg-4 col-12 text-center">
          <h2 class="card__title col-12">Біологія</h2>
<!--          <h3 class="card__text col-12">10 тижнів</h3>-->
          <img src="@/assets/bio.svg" alt="math" class="card__img col-12">
          <a href="#form" class="card__btn card__btn_4 d-flex align-items-center justify-content-center mt-5 col-8">Отримати
            курс</a>
        </div>

        <div class="subjects__card col-lg-4 col-12 text-center">
          <h2 class="card__title col-12">Хімія</h2>
<!--          <h3 class="card__text col-12">10 тижнів</h3>-->
          <img src="@/assets/him.svg" alt="math" class="card__img col-12">
          <a href="#form" class="card__btn card__btn_5 d-flex align-items-center justify-content-center mt-5 col-8">Отримати
            курс</a>
        </div>

        <div class="subjects__card col-lg-4 col-12 text-center">
          <h2 class="card__title col-12">Фізика</h2>
<!--          <h3 class="card__text col-12">10 тижнів</h3>-->
          <img src="@/assets/phis.svg" alt="math" class="card__img col-12">
          <a href="#form" class="card__btn card__btn_6 d-flex align-items-center justify-content-center mt-5 col-8">Отримати
            курс</a>
        </div>

        <div class="subjects__card col-lg-4 col-12 text-center">
          <h2 class="card__title col-12">Англійська мова</h2>
<!--          <h3 class="card__text col-12">9 тижнів</h3>-->
          <img src="@/assets/eng.svg" alt="math" class="card__img col-12">
          <a href="#form" class="card__btn card__btn_7 d-flex align-items-center justify-content-center mt-5 col-8">Отримати
            курс</a>
        </div>

        <div class="subjects__card col-lg-4 col-12 text-center">
          <h2 class="card__title col-12">Французька мова</h2>
<!--          <h3 class="card__text col-12">8 тижнів</h3>-->
          <img src="@/assets/fr.svg" alt="math" class="card__img col-12">
          <a href="#form" class="card__btn card__btn_8 d-flex align-items-center justify-content-center mt-5 col-8">Отримати
            курс</a>
        </div>

        <div class="subjects__card col-lg-4 col-12 text-center">
          <h2 class="card__title col-12">Німецька мова</h2>
<!--          <h3 class="card__text col-12">8 тижнів</h3>-->
          <img src="@/assets/de.svg" alt="math" class="card__img col-12">
          <a href="#form" class="card__btn card__btn_9 d-flex align-items-center justify-content-center mt-5 col-8">Отримати
            курс</a>
        </div>

        <div class="subjects__card col-lg-4 col-12 text-center">
          <h2 class="card__title col-12">Іспанська мова</h2>
<!--          <h3 class="card__text col-12">8 тижнів</h3>-->
          <img src="@/assets/es.svg" alt="math" class="card__img col-12" style="height: 100%">
          <a href="#form" class="card__btn card__btn_11 d-flex align-items-center justify-content-center mt-5 col-8">Отримати
            курс</a>
        </div>

        <div class="subjects__card col-lg-4 col-12 text-center">
          <h2 class="card__title col-12">Географія</h2>
          <!--          <h3 class="card__text col-12">10 тижнів</h3>-->
          <img src="@/assets/geo.svg" alt="math" class="card__img col-12">
          <a href="#form" class="card__btn card__btn_10 d-flex align-items-center justify-content-center mt-5 col-8">Отримати
            курс</a>
        </div>

        <div class="subjects__card col-lg-4 col-12 text-center">
          <h2 class="card__title col-12">Українська література</h2>
          <!--          <h3 class="card__text col-12">10 тижнів</h3>-->
          <img src="@/assets/lit.svg" alt="math" class="card__img col-12">
          <a href="#form" class="card__btn card__btn_12 d-flex align-items-center justify-content-center mt-5 col-8">Отримати
            курс</a>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Subjects"
}
</script>

<style scoped>
.subjects__cards {
  width: 100%;
  margin-top: calc(100% / 12);
}

.subjects__cards__layout {
  margin-top: 50px;
}

.subjects__title {
  text-align: center;
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  font-size: 70px;
  font-weight: 600;
  line-height: 0.8;
}

.card__img {
  margin: auto;
  display: flex;
  max-width: 130px;
  max-height: 130px;
}

.card__title {
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  height: 80px;
  line-height: 40px;
  margin-top: 10px;
  font-size: 36px;
  float: right;
}

.card__text {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  margin-bottom: 30px;
}

.subjects__card {
  margin-top: 50px;
  padding: 30px;
  background: #fff;
  border-radius: 71px;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.2);
  transition: 1s;
  max-width: 410px;
}

.subjects__card:nth-child(1):hover {
  box-shadow: 2px 4px 16px rgba(140, 159, 215, 1);
  transition: 0.5s;
}

.subjects__card:nth-child(2):hover {
  box-shadow: 2px 4px 16px rgba(244, 142, 105, 0.65);
  transition: 0.5s;
}

.subjects__card:nth-child(3):hover {
  box-shadow: 2px 4px 16px rgba(249, 213, 114, 0.65);
  transition: 0.5s;
}

.subjects__card:nth-child(4):hover {
  box-shadow: 2px 4px 16px #A8DD89;
  transition: 0.5s;
}

.subjects__card:nth-child(5):hover {
  box-shadow: 2px 4px 16px rgba(98, 175, 232, 0.9);
  transition: 0.5s;
}

.subjects__card:nth-child(6):hover {
  box-shadow: 2px 4px 16px #C8EA3B;
  transition: 0.5s;
}

.subjects__card:nth-child(7):hover {
  box-shadow: 2px 4px 16px rgba(156, 142, 239, 0.69);
  transition: 0.5s;
}

.subjects__card:nth-child(8):hover {
  box-shadow: 2px 4px 16px rgba(228, 122, 234, 0.72);
  transition: 0.5s;
}

.subjects__card:nth-child(9):hover {
  box-shadow: 2px 4px 16px #62AFE8;
  transition: 0.5s;
}

.subjects__card:nth-child(10):hover {
  box-shadow: 2px 4px 16px #EB765D;
  transition: 0.5s;
}

.subjects__card:nth-child(11):hover {
  box-shadow: 2px 4px 16px #82EFF6;
  transition: 0.5s;
}

.subjects__card:nth-child(12):hover {
  box-shadow: 2px 4px 16px #F1C838;
  transition: 0.5s;
}

.card__btn {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: 28px;
  padding: 10px 0;
  margin: auto;
  background: transparent;
  color: #212121;
  border-radius: 78px;
  transition: 0.3s ease-out;
}

.card__btn_1 {
  border: solid 2px #8C9FD7;
}

.card__btn_2 {
  border: solid 2px #F48E69;
}

.card__btn_3 {
  border: solid 2px #EFCE7D;
}

.card__btn_4 {
  border: solid 2px #A8DD89;
}

.card__btn_5 {
  border: solid 2px #62AFE8;
}

.card__btn_6 {
  border: solid 2px #C8EA3B;
}

.card__btn_7 {
  border: solid 2px #9C8EEF;
}

.card__btn_8 {
  border: solid 2px #E47AEA;
}

.card__btn_9 {
  border: solid 2px #62AFE8;
}

.card__btn_10 {
  border: solid 2px #82EFF6;
}

.card__btn_11 {
  border: solid 2px #EB765D;
}
.card__btn_12 {
  border: solid 2px #F1C838;
}

.card__btn_1:hover {
  background: #8C9FD7;
  color: white;
  transition: 0.2s ease-in;
}

.card__btn_2:hover {
  background: #F48E69;
  color: white;
  transition: 0.2s ease-in;
}

.card__btn_3:hover {
  background: #EFCE7D;
  color: white;
  transition: 0.2s ease-in;
}
</style>
