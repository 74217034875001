<template>
  <lessonvue/>
</template>

<script>
// @ is an alias to /src


import lessonvue from "../components/lessonvue";

export default {
  name: 'lesson',
  components: {
    lessonvue,
  },
  data() {
    return {}
  }
}
</script>

