<template>
  <div class="users container-fluid text-center mt-5">
    <user/>
  </div>
</template>

<script>
// @ is an alias to /src
import user from "../components/user";
import {mapGetters} from 'vuex'
import router from "../router";

export default {
  name: 'Users',
  components: {
    user,
  },
  created() {
    if (this.user.role !== 1){
      router.push('/')
    }
  },
  computed: {
    ...mapGetters({user:'user/user'})
  }
}

</script>


<style scoped>
h6 {
  font-family: e-Ukraine, -apple-system, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.users {
  padding: 30px;
  border-radius: 16px;
}

</style>

